import React from "react";
import { Box, TableBody, TableCell, TableRow, Typography, useTheme } from "@mui/material";
import { Circle } from "@mui/icons-material";

import { ITableData, ITableRowFrame, OrderEnum, StatusTypeEnum } from "./types";
import { handleSort, transformCellText } from "./handler";

interface ITBodyProps {
  tableCellData: ITableRowFrame[];
  transactionData: ITableData[];
  order?: OrderEnum;
  orderBy?: string;
}

const TBody: React.FC<ITBodyProps> = ({ tableCellData, transactionData = [], order = OrderEnum.ASC, orderBy = "" }) => {
  const theme = useTheme();

  return (
    <TableBody>
      {handleSort(transactionData, order, orderBy).map((row: ITableData, i) => {
        return (
          <TableRow hover tabIndex={-1} key={i}>
            {tableCellData
              .filter(({ isVisible = true }) => isVisible)
              .map(({ name, type = "text", statusKey, onClick = () => {} }, i) => (
                <TableCell
                  key={i}
                  sx={{ "&.MuiTableCell-root": { borderRight: "1px solid #c6c6c6", padding: "10px 12px" } }}
                >
                  {type === "multiple" ? (
                    row?.[name] &&
                    row?.[name].map((e: any, i) => (
                      <Box sx={{ display: "flex" }} key={i}>
                        {statusKey && (
                          <Circle
                            sx={{
                              color:
                                e.status?.toUpperCase() === StatusTypeEnum.SUCCESS || e.status?.toUpperCase() === StatusTypeEnum.ERROR
                                  ? e.status?.toUpperCase() === StatusTypeEnum.ERROR
                                    ? theme.palette.error.main
                                    : theme.palette.success.main
                                  : "transparent",
                              mt: i === 0 ? 0 : 1,
                            }}
                          />
                        )}
                        <Typography
                          ml={1}
                          mt={i === 0 ? 0 : 1}
                          whiteSpace={"nowrap"}
                          minHeight={24}
                          onClick={() => onClick({ ...row, [name]: e.number })}
                          component="div"
                          sx={{
                            textDecoration: statusKey && e.status?.toUpperCase() === StatusTypeEnum.ERROR ? "underline" : "none",
                            cursor: statusKey && e.status?.toUpperCase() === StatusTypeEnum.ERROR ? "pointer" : "default",
                          }}
                        >
                          {e.number}
                        </Typography>
                      </Box>
                    ))
                  ) : (
                    <Box sx={{ display: "flex" }}>
                      {statusKey && type === "status" && (
                        <Circle
                          sx={{
                            color:
                              row?.[statusKey]?.toUpperCase() === StatusTypeEnum.SUCCESS || row?.[statusKey]?.toUpperCase() === StatusTypeEnum.ERROR
                                ? row?.[statusKey]?.toUpperCase() === StatusTypeEnum.ERROR
                                  ? theme.palette.error.main
                                  : theme.palette.success.main
                                : "transparent",
                          }}
                        />
                      )}
                      <Typography
                        ml={1}
                        whiteSpace={"nowrap"}
                        minHeight={24}
                        onClick={() => onClick(row)}
                        component="div"
                        sx={{
                          textDecoration: statusKey && row?.[statusKey]?.toUpperCase() === StatusTypeEnum.ERROR ? "underline" : "none",
                          cursor: statusKey && row?.[statusKey]?.toUpperCase() === StatusTypeEnum.ERROR ? "pointer" : "default",
                        }}
                      >
                        {transformCellText(type, row, name)}
                      </Typography>
                    </Box>
                  )}
                </TableCell>
              ))}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default TBody;
