import { Box, Container, Paper, Stack, Typography } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, LinearScale, Title, Tooltip, Legend, ArcElement } from "chart.js";

import { productChartData, options } from "./constants";

ChartJS.register(LinearScale, ArcElement, Title, Tooltip, Legend);

const CatalogHeader = ({products}) => {
  return (
    <Paper elevation={3} sx={{ mt: 1 }}>
      <Container maxWidth="xl">
        <Stack direction="row" justifyContent="space-between">
          <Box maxWidth={1200}>
            <Typography variant="h1" sx={{ my: 2 }}>
              Integration Catalog
            </Typography>

            <Typography variant="body1">
              Introducing our comprehensive Integration Catalog – a centralized hub capturing the essence of all integrations within
              our organization. This dynamic resource provides a snapshot of our interconnected ecosystem, detailing
              business, functional, and technical aspects. Your go-to guide for a holistic view of our integration
              landscape. Explore, innovate, and elevate your integration experience. Welcome to the future of
              connectivity.
            </Typography>
          </Box>
          <Box sx={{ height: 250 }}>
            <Doughnut options={options} data={productChartData(products)} />
          </Box>
        </Stack>
      </Container>
    </Paper>
  );
};

export default CatalogHeader;
