/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Stack } from "@mui/material";

import NavigateBack from "../navbar/NavigateBack";
import BasicHeader from "../common/BasicHeader";
import BasicTabs from "../common/BasicTabs";
import DelimitBox from "../common/DelimitBox";
import { getApi } from "../../service";
import { selfServiceTabs, config } from "./constants";
import { configurationReview } from "./helper";
import CommonConfiguration from "./CommonConfiguration";
import { useConfig } from "../../providers/ConfigProvider";

const SelfServiceConfiguration = () => {
  const location = useLocation();
  const { setConfigState } = useConfig();

  useEffect(() => {
    (async () => {
      const data = await getApi(`${process.env.REACT_APP_URL}/configurations`);
      setConfigState(configurationReview(data.data));
    })();
  }, []);

  return (
    <Stack direction="column">
      <NavigateBack headerText="SELF SERVICE" />
      <DelimitBox customStyle={{ mt: 2 }}>
        <Stack sx={{ m: "20px" }}>
          <BasicHeader title="Configuration Settings"></BasicHeader>
        </Stack>
        <Stack sx={{ m: "20px" }}>
          <BasicTabs
            data={selfServiceTabs.map((e) => ({ label: e[0].toUpperCase() + e.substring(1) }))}
            components={config.map((e) => (
              <CommonConfiguration {...e} />
            ))}
            defaultTab={location.state?.defaultTab}
          />
        </Stack>
      </DelimitBox>
    </Stack>
  );
};

export default SelfServiceConfiguration;
