import React from "react";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";

import { chartComponents } from "./constants";
import { IChartProps } from "./types";

ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip
);

const Chart: React.FC<IChartProps> = ({ chartType, data, options }) => {
  const DynamicComponent = chartComponents[chartType];
  return <DynamicComponent data={data} options={options} />;
};

export default Chart;
