/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, Button, Collapse, Popover, Stack, Typography, useTheme } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { IOptionData, ViewTypeEnum } from "./types";
import { ISearchFilterOptions } from "../types";

interface IDropdown extends Omit<Omit<ISearchFilterOptions, "label">, "placeholder"> {
  label?: string;
  placeholder?: string;
}

const Dropdown: React.FC<IDropdown> = ({
  optionData = [],
  preSelectedValue = {},
  handleValueChange = () => {},
  viewType = ViewTypeEnum.EDITABLE,
  label,
  disabled,
  customStyle,
  placeholder,
}) => {
  const theme = useTheme();
  const { pathname } = useLocation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [status, setStatus] = useState<IOptionData>(preSelectedValue);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => setStatus(preSelectedValue), [pathname]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => !disabled && setAnchorEl(event.currentTarget);

  const CustomIcon = () => status.icon ?? <></>;

  return (
    <Box sx={customStyle}>
      {viewType === ViewTypeEnum.READABLE ? (
        <>
          <Typography variant="subtitle1">{label}</Typography>
          <Typography variant="subtitle2" sx={{ mt: 1 }}>
            {status.value ?? "-"}
          </Typography>
        </>
      ) : (
        <>
          {label && (
            <Typography variant="h3" sx={{ mb: 1 }}>
              {label}
            </Typography>
          )}
          <Button
            onClick={handleClick}
            startIcon={status.label && <CustomIcon />}
            endIcon={open ? <ExpandLess color="primary" /> : <ExpandMore color={disabled ? "disabled" : "primary"} />}
            sx={{
              border: open ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.text.disabled}`,
              borderRadius: "4px",
              width: "100%",
              minWidth: 180,
              height: 34,
              px: 1,
              justifyContent: "start",
              color: status.label ? `${theme.palette.text.primary}` : `${theme.palette.text.secondary}`,
              fontSize: "16px",
              padding: "10px 12px",
              ".MuiButton-startIcon": {
                margin: 0,
                paddingRight: "8px",
                alignSelf: "start",
              },
              ".MuiButton-endIcon": {
                flex: 1,
                justifyContent: "end",
              },
              background: disabled ? "#F4F4F4" : "#fff",
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: `${theme.palette.text.secondary}`,
              },
            }}
          >
            {status.label || placeholder || "Select"}

          </Button>
        </>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          marginTop: "4px",
          ".MuiPaper-root": {
            borderRadius: "8px",
          },
        }}
      >
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Stack
            sx={{
              width: "100%",
              minWidth: 204,
              flexDirection: "column",
            }}
          >
            {optionData.map(({ value, label, icon }) => {
              const CustomIcon = () => icon ?? <></>;
              return (
                <Button
                  startIcon={icon && <CustomIcon />}
                  onClick={() => {
                    handleValueChange({ value, label, icon });
                    setStatus({ value, label, icon });
                    setAnchorEl(null);
                  }}
                  key={value}
                  sx={{
                    justifyContent: "start",
                    alignItems: "baseline",
                    color: `${theme.palette.text.primary}`,
                    padding: "10px 12px",
                    ".MuiButton-startIcon": {
                      margin: 0,
                      paddingRight: "8px",
                    },
                  }}
                >
                  {label}
                </Button>
              );
            })}
          </Stack>
        </Collapse>
      </Popover>
    </Box>
  );
};

export default Dropdown;
