import { FC, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";

import { postApi } from "../../../service";
import { IProductWatchForm } from "../types";
import TrendInfoCard from "../../common/card/TrendInfoCard";
import { useEnvironment } from "../../../providers/EnvironmentProvider";

interface ITimeSummaryResponse {
  startDate: string;
  endDate: string;
  totalNumberOfTransactionsOverThreeSeconds: number;
  trasactionIdForMaxResponseTime: string;
  maxResponseTime: number;
}

interface ITimeSummaryProps {
  formDetails: UseFormReturn<IProductWatchForm, any, undefined>;
  isSearched: boolean;
}

const TimeSummary: FC<ITimeSummaryProps> = ({ formDetails, isSearched }) => {
  const { getValues } = formDetails;
  const { environmentState } = useEnvironment();

  const [resData, setResData] = useState<ITimeSummaryResponse | null>(null);

  useEffect(() => {
    (async () => {
      const val = getValues();
      let res: { data: { data: ITimeSummaryResponse }; status: number } = await postApi(
        `${process.env.REACT_APP_POST}`,
        {
          org: "hmd-jjcc-nonprod-qa",
          sector: "medical",
          api_name: "PerformanceInsightForTransactionTimeInsights",
          ...val,
          env: environmentState ? environmentState : process.env.PERFORMANCE_INDEX || null,
        }
      );

      try {
        setResData(res.data.data);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [isSearched]);

  return (
    <>
      {resData && (
        <>
          <TrendInfoCard
            isTrendingUp={true}
            content={`The max time taken is ${(resData.maxResponseTime / 1000).toFixed(2)}s`}
            value={`transaction_id is ${resData.trasactionIdForMaxResponseTime}`}
          />
          <TrendInfoCard
            isTrendingUp={true}
            content={`The amount of transaction that took >3s to complete is`}
            value={resData.totalNumberOfTransactionsOverThreeSeconds.toString()}
          />
        </>
      )}
    </>
  );
};

export default TimeSummary;
