/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Skeleton, Typography } from "@mui/material";

import Table from "../common/table/Table";
import DelimitBox from "../common/DelimitBox";
import BasicHeader from "../common/BasicHeader";
import InputFilters, { filterData } from "../common/InputFilters";
import { ITableData, ITableRowFrame } from "../common/table/types";
import { sapTransactions } from "../configurationSettings/constants";
import { postApi } from "../../service";
import { createTableSearchObject } from "./handler";
import { useUser } from "../../providers/UserProvider";

export interface ITransactionProps {
  tableLayout: ITableRowFrame[];
  filterLayout: any[];
  header: string;
  defaultValues?: any;
}

// Temp data

const Transactions: React.FC<ITransactionProps> = ({ tableLayout, filterLayout, header, defaultValues = {} }) => {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const { getValues, setValue, control, reset } = useForm({ defaultValues: { ...defaultValues } });
  const watchForm = useWatch({ control });
  const { user } = useUser();
  const [transactionData, setTransactionData] = useState<ITableData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [flexibleTableColumn, setFlexibleTableColumn] = useState<ITableRowFrame[]>([]);
  const [filter, setFilter] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const requestData = {
    api_name: "DetailList",
    index_name: `${process.env.REACT_APP_ORG}-${process.env.REACT_APP_SECTOR}-order-details`,
    sort_by_datetime: "desc",
    countries: user.countries || [],
    org: process.env.REACT_APP_ORG,
    sector: process.env.REACT_APP_SECTOR,
  };
  /* 
    ===== FOR SEARCH =====
  */
  const handleSearch = () => {
    setIsLoading(true);
    const timeOutId = setTimeout(async () => {
      const res = await postApi(`${process.env.REACT_APP_POST}`, {
        ...requestData,
        page,
        size,
        ...createTableSearchObject(watchForm),
      });

      setIsLoading(false);
      setTransactionData(res.data.data.map((e) => ({ ...e, ...e._source })));

      setCount(res.data.count);
    }, 500);

    return () => clearTimeout(timeOutId);
  };

  const handleClearInputs = () => {
    window.history.replaceState({}, document.title);
    window.location.reload();
  };

  useEffect(() => {
    setFlexibleTableColumn(
      tableLayout.map((e) => {
        const keyTab = sapTransactions.find((ele) => ele + "Number" === e.name);

        if (e.name === "gatewayOrderNumber") {
          return {
            ...e,
            onClick: (id: any) =>
              navigate(`/self-service/jjcc/details/inboundOrders?inboundOrders=${id.gatewayOrderNumber}`),
          };
        }

        if (keyTab) {
          return {
            ...e,
            onClick: (id: any) => {
              const sapOrderNumber = id.sapOrderNumber;
              const deliveryNumber = id.deliveryNumber
                ? Array.isArray(id.deliveryNumber)
                  ? id.deliveryNumber[0].number
                  : id.deliveryNumber
                : "";
              const invoiceNumber = id.invoiceNumber
                ? Array.isArray(id.invoiceNumber)
                  ? id.invoiceNumber[0].number
                  : id.invoiceNumber
                : "";

              navigate(
                `/self-service/sap/details/${keyTab}?sapOrderNumber=${sapOrderNumber}&deliveryNumber=${deliveryNumber}&invoiceNumber=${invoiceNumber}`
              );
            },
          };
        }

        return e;
      })
    );

    setFilter(filterData({ filterLayout, setValue, getValues }));
  }, []);

  useEffect(() => {
    if (state) {
      reset();
      setIsLoading(true);
      const timeOutId = setTimeout(async () => {
        let res = await postApi(`${process.env.REACT_APP_POST}`, {
          ...requestData,
          page,
          size,
          ...createTableSearchObject(state),
        });

        setIsLoading(false);

        setTransactionData(res.data.data.map((e) => ({ ...e, ...e._source })));
        setCount(res.data.count);
      }, 500);

      return () => clearTimeout(timeOutId);
    } else {
      reset();
      setIsLoading(true);
      const timeOutId = setTimeout(async () => {
        let res = await postApi(`${process.env.REACT_APP_POST}`, {
          ...requestData,
          page,
          size,
          ...createTableSearchObject(watchForm),
        });

        setIsLoading(false);

        setTransactionData(res.data.data.map((e) => ({ ...e, ...e._source })));
        setCount(res.data.count);
      }, 500);

      return () => clearTimeout(timeOutId);
    }
  }, [pathname, page, size]);

  return (
    <DelimitBox customStyle={{ p: 0, mt: 2 }}>
      <BasicHeader title={header} customStyle={{ p: "20px" }} />
      <InputFilters filter={filter} getValues={getValues} />
      <Button variant="contained" sx={{ ml: 3, mb: 2, width: "10vw", fontSize: "16px" }} onClick={handleSearch}>
        Search
      </Button>
      <Button variant="outlined" sx={{ ml: 3, mb: 2, width: "10vw", fontSize: "16px" }} onClick={handleClearInputs}>
        Reset
      </Button>

      {isLoading ? (
        <Skeleton variant="rounded" width={"100%"} height={360} sx={{ mb: 2 }} />
      ) : transactionData && transactionData.length < 1 ? (
        <Typography align="center" sx={{ pt: 10, pb: 10 }}>
          No data to display
        </Typography>
      ) : (
        <Table
          transactionData={transactionData}
          isViewMoreBtnExist={false}
          flexibleTableColumn={flexibleTableColumn}
          setFlexibleTableColumn={setFlexibleTableColumn}
          page={page}
          setPage={setPage}
          size={size}
          setSize={setSize}
          count={count}
        />
      )}
    </DelimitBox>
  );
};

export default Transactions;
