// all counties with values
export const countryDetails = {
  IN: { name: "India", countryCode: "IN", currency: "INR", currencyToken: "₹" , dateFormat: "DD.MM.YYYY"},
  AU: { name: "Australia", countryCode: "AU", currency: "AUD", currencyToken: "$", dateFormat: "DD.MM.YYYY"},
  SG: { name: "Singapore", countryCode: "SG", currency: "SGD", currencyToken: "$", dateFormat: "DD.MM.YYYY"},
  NZ: { name: "New Zealand", countryCode: "NZ", currency: "NZD", currencyToken: "$", dateFormat: "DD.MM.YYYY"},
  KR: { name: "South Korea", countryCode: "KR", currency: "KRW", currencyToken: "₩", dateFormat: "YYYY-MM-DD"},
  TH: { name: "Thailand", countryCode: "TH", currency: "THB", currencyToken: "฿", dateFormat: "DD.MM.YYYY"},
  PH: { name: "Philippines", countryCode: "PH", currency: "PHP", currencyToken: "₱", dateFormat: "DD.MM.YYYY"},
  CN: { name: "China", countryCode: "CN", currency: "CNY", currencyToken: "￥", dateFormat: "YYYY-MM-DD"},
  TW: { name: "Taiwan", countryCode: "TW", currency: "TWD", currencyToken: "$", dateFormat: "YYYY-MM-DD"},
  MY: { name: "Malaysia", countryCode: "MY", currency: "MYR", currencyToken: "RM", dateFormat: "DD.MM.YYYY"},
  HK: { name: "Hong Kong", countryCode: "HK", currency: "HKD", currencyToken: "$", dateFormat: "YYYY-MM-DD"},
};
// all counties with values
export const countryDetailsWithNames = {
  India: { name: "India", countryCode: "IN", currency: "INR", localeCode:"en" },
  Australia: { name: "Australia", countryCode: "AU", currency: "AUD" , localeCode:"en"},
  Singapore: { name: "Singapore", countryCode: "SG", currency: "SGD", localeCode:"en" },
  "New Zealand": { name: "New Zealand", countryCode: "NZ", currency: "NZD", localeCode:"en" },
  "South Korea": { name: "South Korea", countryCode: "KR", currency: "KRW" , localeCode:"en"},
  Thailand: { name: "Thailand", countryCode: "TH", currency: "THB" , localeCode:"en"},
  Philippines: { name: "Philippines", countryCode: "PH", currency: "PHP" , localeCode:"en"},
  China: { name: "China", countryCode: "CN", currency: "CNY", localeCode:"cn" },
  Taiwan: { name: "Taiwan", countryCode: "TW", currency: "TWD" , localeCode:"en"},
  Malaysia: { name: "Malaysia", countryCode: "MY", currency: "MYR" , localeCode:"en"},
  "Hong Kong": { name: "Hong Kong", countryCode: "HK", currency: "HKD", localeCode:"en" },
};
