export const formatTotalSalesPerCustomerPerMonthData = (data: any) => {
  let monthString: any = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };
  let datasetDict: any = new Map();
  let allMonthDicts: any = new Map();
  let allaccountIds: any = new Map();
  for (let index = 0; index < data.length; index += 1) {
    allaccountIds[data[index].accountId] = new Map();
    allMonthDicts[monthString[data[index].month]] = new Map();
  }
  for (let index = 0; index < data.length; index += 1) {
    let accId = data[index].accountId;
    datasetDict[accId] = new Map();
  }
  let allAccountIdsArray = Object.keys(allaccountIds);
  let monthArrayLables = Object.keys(allMonthDicts);
  for (let index = 0; index < allAccountIdsArray.length; index += 1) {
    for (let idx = 0; idx < monthArrayLables.length; idx += 1) {
      let month = monthArrayLables[idx];
      datasetDict[allAccountIdsArray[index]][month] = 0;
    }
  }
  for (let index = 0; index < data.length; index += 1) {
    let month = monthString[data[index].month];
    let accountId = data[index].accountId;
    let totalSales = data[index].totalSales;
    datasetDict[accountId][month] = totalSales;
  }
  const dynamicColors = () => {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgba(" + r + "," + g + "," + b + ", 0.7)";
  };
  let datasetArray: any = [];
  for (let index = 0; index < allAccountIdsArray.length; index += 1) {
    let dataForAccountId: any = [];
    let accId = datasetDict[allAccountIdsArray[index]];
    for (const value of Object.values(accId)) {
      dataForAccountId.push(value);
    }
    datasetArray.push({
      label: allAccountIdsArray[index],
      data: dataForAccountId,
      borderColor: dynamicColors(),
    });
  }
  let dataForChart = {
    labels: monthArrayLables,
    datasets: datasetArray,
  };
  return dataForChart;
};
