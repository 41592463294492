/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { OpenInFull } from "@mui/icons-material";
import { Bar } from "react-chartjs-2";

import BasicHeader from "../common/BasicHeader";
import BasicTabs from "../common/BasicTabs";
import DelimitBox from "../common/DelimitBox";
import Dropdown from "../common/inputs/Dropdown";
import MaximizeModal from "../common/modal/MaximizeModal";
import { modifyBarChartData } from "../common/chart/helper";
import { barDataSet } from "../ordersOverview/constants";
import { dataKeyType, DayOption, DayType, OptionDataN } from "../ordersOverview/types";
import { dayOptions } from "./constants";
import { postApi } from "../../service";
import { options, tabData } from "./constants";
import { useUser } from "../../providers/UserProvider";

interface IProductLinesProps {}

const ProductLines: React.FC<IProductLinesProps> = () => {
  const [openFull, setOpenFull] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dayOptionState, setDayOptionState] = useState<OptionDataN>({
    value: DayType.SEVEN,
    label: DayOption.SEVEN,
  });
  const [tabComponentData, setTabComponentData] = useState<any[]>([]);
  const { user } = useUser();
  const commonReq = {
    api_name: "Chart",
    countries: user.countries || [],
    chartType: "pl",
    filterType: dayOptionState.value,
    org: process.env.REACT_APP_ORG,
    sector: process.env.REACT_APP_SECTOR,
  };
  const leastRequestObject = {
    ...commonReq,
    cType: "least",
  };

  const mostRequestObject = {
    ...commonReq,
    cType: "most",
  };

  useEffect(() => {
    (async () => {
      let res1 = await postApi(`${process.env.REACT_APP_POST}`, mostRequestObject);
      let res2 = await postApi(`${process.env.REACT_APP_POST}`, leastRequestObject);

      if (res1?.data?.productNames && res1?.data?.productNames.length > 1) {
        res1?.data?.productNames.forEach((element, index) => {
          res1?.data?.sales.forEach((elem, ind) => {
            if (element.productCode === elem.product) {
              res1.data.sales[ind].product = res1?.data?.productNames[index].productCode;
            }
          });
        });
      }

      if (res2?.data?.productNames && res2?.data?.productNames.length > 1) {
        res2.data.productNames.forEach((element) => {
          res2.data.sales.forEach((elem, ind) => {
            if (element.productCode === elem.product) {
              res2.data.sales[ind].product = element.productCode;
            }
          });
        });
      }
      

      setTabComponentData([
        modifyBarChartData({
          data: res1.data.data.sales,
          dataSet: barDataSet,
          dayType: "product",
          dataKey: [dataKeyType.NEW_SALE, dataKeyType.OLD_SALE],
          backgroundColor: ["#00B5E2", "#753BBD"],
        }),
        modifyBarChartData({
          data: res2.data.data.sales,
          dataSet: barDataSet,
          dayType: "product",
          dataKey: [dataKeyType.NEW_SALE, dataKeyType.OLD_SALE],
          backgroundColor: ["#00B5E2", "#753BBD"],
        }),
      ]);
      setIsLoading(false);
    })();
  }, [dayOptionState]);

  return (
    <DelimitBox customStyle={{ width: "calc(50% - 8px)", ml: 1 }}>
      <MaximizeModal open={openFull} setOpen={setOpenFull}>
        <BasicHeader title="Product Lines">
          <Dropdown
            optionData={dayOptions}
            preSelectedValue={dayOptionState}
            handleValueChange={(val) => setDayOptionState(val as OptionDataN)}
          />
          <OpenInFull
            sx={{ height: 34, ml: 2, cursor: "pointer" }}
            color="primary"
            onClick={() => setOpenFull(!openFull)}
          />
        </BasicHeader>

        {isLoading ? (
          "...Loading"
        ) : (
          <BasicTabs
            data={tabData}
            components={[
              ...tabComponentData.map((e, i) => {
                // Modify the dataset labels
                e?.datasets?.forEach((element: any) => {
                  if (element?.label && !element.label.includes(" ")) {
                    element.label =
                      element.label.charAt(0).toUpperCase() +
                      element.label
                        .substr(1)
                        .replace(/([A-Z])/g, " $1")
                        .trim();
                  }
                });
            
                // Return the JSX element
                return (
                  <div key={i} style={{ height: openFull ? "65vh" : "45vh" }}>
                    <Bar data={e} options={options} key={i} />
                  </div>
                );
              }),
            ]}
            
          />
        )}
      </MaximizeModal>
    </DelimitBox>
  );
};

export default ProductLines;