import React, { useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ArrowRightAlt, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

import { IPastActionData } from "../types";

interface ICollapsibleTableProps {
  pastActionData: IPastActionData[];
}

const Row = ({ row }: { row: IPastActionData }) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <TableRow sx={{ margin: 0, height: "44px" }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" color="primary" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell align="left" component="th" scope="row">
          <Typography variant="h3">Feed {row.feed}</Typography>
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 0, width: "inherit" }}>
              <Table sx={{ width: "inherit" }} aria-label="purchases">
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.columnName}>
                      <TableCell />
                      <TableCell align="left" component="th" scope="row">
                        {historyRow.columnName}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderLeft: "1px solid #c6c6c6",
                          display: "flex",
                          flexDirection: "row",
                          columnGap: "10px",
                        }}
                        colSpan={3}
                      >
                        <Typography sx={{ color: "#ff0000" }}>{historyRow.fromValue}</Typography>
                        <ArrowRightAlt />
                        <Typography>{historyRow.toValue}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const CollapsibleTable: React.FC<ICollapsibleTableProps> = ({ pastActionData }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" sx={{ padding: 0, margin: 0 }}>
        <TableHead>
          <TableRow
            sx={{
              borderRight: "1px solid #c6c6c6",
              borderTop: "1px solid #c6c6c6",
              backgroundColor: "#f2f9fb",
              height: "44px",
            }}
          >
            <TableCell sx={{ width: 1 / 8 }} />
            <TableCell sx={{ width: 1 / 4 }}>
              <Typography variant="h3">Field Edited</Typography>
            </TableCell>
            <TableCell
              sx={{
                width: 5 / 8,
                paddingLeft: "80px",
                borderRight: "1px solid #c6c6c6",
              }}
              align="left"
            >
              <Typography variant="h3">Actions</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pastActionData.map((row) => (
            <Row key={row.version} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CollapsibleTable;
