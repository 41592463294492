import { Theme } from "@emotion/react";
import { BreakpointsOptions, Components, createTheme, PaletteOptions } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";

import JJCircular from "../media/fonts/JJCircular/Regular.otf";
import JJCircularBold from "../media/fonts/JJCircular/Bold.otf";
import JJCircularMedium from "../media/fonts/JJCircular/Medium.otf";

const palette: PaletteOptions = {
  primary: {
    main: "#0C8CAA",
  },
  secondary: {
    main: "#981200",
  },
  text: {
    primary: "#212121",
    secondary: "#63666A",
    disabled: "#D8D8D8",
  },
  error: {
    main: "#d51a01",
  },
  success: {
    main: "#0EB58E",
    dark: "#00724B",
  },
  warning: {
    main: "#FFB32A",
  },
  grey: {
    A100: "#888B8D",
  },
  status: {
    success: "#0EB58E",
    error: "#E1342C",
  },
};

const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1900,
  },
};

const typography: TypographyOptions = {
  fontFamily: "'JJCircular','Helvetica', 'Arial', sans-serif",
  h1: {
    fontWeight: "bold",
    lineHeight: "30px",
    fontSize: "24px",
    //need to fix font family
    fontFamily: "'JJCircularBold','Helvetica', 'Arial', sans-serif",
    color: "#212121",
  },
  h2: {
    fontWeight: "Bold",
    lineHeight: "19px",
    fontSize: "16px",
    fontFamily: "'JJCircularBold','Helvetica', 'Arial', sans-serif",
    color: "#63666A",
  },
  h3: {
    fontWeight: "bold",
    lineHeight: "17px",
    fontSize: "14px",
    //need to fix font family
    fontFamily: "'JJCircularBold','Helvetica', 'Arial', sans-serif",
    color: "#212121",
    letterSpacing: "0.24px",
  },
  h4: {
    fontWeight: "bold",
    lineHeight: "15px",
    fontSize: "12px",
    fontFamily: "'JJCircularBold','Helvetica', 'Arial', sans-serif",
    textDecoration: "underline",
  },
  subtitle1: {
    fontWeight: "bold",
    color: "#888B8D",
    letterSpacing: "0.24px",
    lineHeight: "14px",
    fontSize: "12px",
    fontFamily: "'JJCircularBold','Helvetica', 'Arial', sans-serif",
  },

  subtitle2: {
    fontWeight: "bold",
    color: "#212121",
    letterSpacing: "0.28px",
    lineHeight: "14px",
    fontSize: "14px",
    fontFamily: "'JJCircularBold','Helvetica', 'Arial', sans-serif",
  },
  caption: {
    fontSize: "11px",
    lineHeight: "13px",
    letterSpacing: "0px",
  },
  overline: {
    // fontWeight: "bold",
    color: "#0E8CAA",
    letterSpacing: "0.24px",
    lineHeight: "14px",
    fontSize: "12px",
    fontFamily: "'JJCircularBold','Helvetica', 'Arial', sans-serif",
  },
};

const components: Components = {
  MuiCssBaseline: {
    styleOverrides: {
      "@font-face": {
        fontFamily: "JJCircular",
        src: `url(${JJCircular}) , url(${JJCircularBold}), url(${JJCircularMedium})`,
      },
      body: {
        margin: 0,
        padding: 0,
        fontFamily: "'JJCircular','Helvetica', 'Arial', sans-serif",
        backgroundColor: "#F9F9F9",
      },
    },
  },

  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: "none",
      },
    },

    variants: [
      {
        props: { variant: "buttonOutlined" },
        style: {
          border: "2px solid #0C8CAA",
          padding: "11px 24px",
          lineHeight: 1,
          fontWeight: "bold",
          borderRadius: "20px",
          color: "#0C8CAA",
        },
      },
      {
        props: { variant: "buttonContained" },
        style: {
          border: "2px solid #025569",
          borderRadius: "24px",
          color: "#fff",
          backgroundColor: "#0C8CAA",
        },
      },
      {
        props: { variant: "customButton" },
        style: {
          color: "#212121",
          padding: "10px 12px",
          width: "100%",
          justifyContent: "flex-start",
          fontSize: "16px",
          borderRadius: 0,
          "&:hover": {
            backgroundColor: "#F4F4F4",
          },
        },
      },
    ],
  },
};

const theme: Theme = createTheme({
  palette,
  typography,
  breakpoints,
  components,
});

export default theme;
