import React from "react";
import { Box, SxProps, Typography } from "@mui/material";

import { CustomInputTextField } from "./styles";
import { ViewTypeEnum } from "./types";

interface IInputTextFieldProps {
  name?: string;
  placeholder?: string;
  viewType?: ViewTypeEnum;
  defaultValue?: string;
  disabled?: boolean;
  label?: string;
  customStyle?: SxProps;
  handleValueChange: (val: string) => void;
}

const InputTextField: React.FC<IInputTextFieldProps> = ({
  name,
  label,
  placeholder,
  viewType = ViewTypeEnum.EDITABLE,
  defaultValue,
  disabled,
  handleValueChange,
  customStyle,
}) => {
  return (
    <Box sx={customStyle}>
      {viewType === ViewTypeEnum.READABLE ? (
        <>
          <Typography variant="h6">{label}</Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            {typeof defaultValue === "string" ? defaultValue : "-"}
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h3">{label}</Typography>
          <CustomInputTextField
            disabled={disabled}
            disableUnderline={false}
            defaultValue={defaultValue}
            onChange={(e) => handleValueChange(e.target.value)}
            placeholder={placeholder}
            sx={{
              background: disabled ? "#F4F4F4" : "#fff",
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#63666A",
              },
            }}
          />
        </>
      )}
    </Box>
  );
};

export default InputTextField;
