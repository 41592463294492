import React, { createContext, useContext, useState } from "react";
import { IChildrenProps } from "../components/common/types";

export interface IAuthState {
  access_token?: string;
  expires_in?: Date;
  code: string;
  refresh_token?: string;
}

export interface IAuthContext {
  auth: IAuthState;
  setAuth: (auth: IAuthState) => void;
}

export const AuthContext = createContext<IAuthContext>({
  auth: {
    access_token: "",
    expires_in: new Date(),
    code: ``,
  },
  setAuth: () => {},
});

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};

const getLocalCode = () => {
  const localData = localStorage.getItem("auth") || "";
  try {
    const localAuth = localData && JSON.parse(localData);
    return localAuth?.code ?? "";
  } catch (err) {
    console.error(err);
  }
  return "";
};

export const AuthProvider: React.FC<IChildrenProps> = ({ children }) => {
  const [auth, setAuth] = useState<IAuthState>({
    access_token: "",
    expires_in: new Date(),
    code: getLocalCode(),
  });

  return <AuthContext.Provider value={{ auth, setAuth }}>{children}</AuthContext.Provider>;
};
