import { MouseEvent } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

import { useEnvironment } from "../../providers/EnvironmentProvider";

const EnvironmentToggle = () => {
  const { environmentState, setEnvironmentState } = useEnvironment();

  const handleChange = (event: MouseEvent<HTMLElement>, val: string) => {
    setEnvironmentState(val);
  };

  return (
    <ToggleButtonGroup color="primary" value={environmentState} exclusive onChange={handleChange} aria-label="Platform">
      <ToggleButton value="dev">dev</ToggleButton>
      <ToggleButton value="staging">qa</ToggleButton>
    </ToggleButtonGroup>
  );
};

export default EnvironmentToggle;
