import { Box, Button, Typography } from "@mui/material";

const Error = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "calc(100vh - 254px)",
      }}
    >
      <Typography variant="h1">404</Typography>
      <Typography variant="h6">The page you’re looking for doesn’t exist.</Typography>
      <Button variant="contained">Back Home</Button>
    </Box>
  );
};

export default Error;
