/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ChartOptions,
} from "chart.js";
import { useTranslation } from "react-i18next";

import { AlertTypeEnum, useAlert } from "../../../providers/AlertProvider";
import Chart from "../../common/chart/Chart";
import { ChartTypeEnum } from "../../common/chart/constants";
import { productQuantityDataToChartData } from "../helper";
import ProductMultiSelect from "./ProductMultiSelect";
import { postApi } from "../../../service";
import { useUser } from "../../../providers/UserProvider";
import { IProduct, IWatchForm } from ".";
import Loader from "../../common/loader/Loader";
import { countryDetails } from "../../common/constants/countries";
import { IFormDetails } from "../../common/types";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

interface IProductTrendProps {
  onSearch: boolean;
  formDetails: IFormDetails;
  formData: IWatchForm;
  apiName: string;
  label: string;
  selectedProducts: { [key in string]: IProduct };
  handleSelectedProducts: (val: { [key in string]: IProduct }) => void;
  chartOptions: ChartOptions;
}

const ProductTrend: FC<IProductTrendProps> = ({
  onSearch,
  formDetails,
  formData,
  apiName,
  label,
  selectedProducts,
  handleSelectedProducts,
  chartOptions,
}) => {
  const { t } = useTranslation();
  const { setValue } = formDetails;
  const { addAlert } = useAlert();
  const { user } = useUser();
  const dateFormat = countryDetails[user.countries[0]].dateFormat;

  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const searchForProduct = async (value) => {
    try {
      const productDetailsByName = await postApi(
        `${process.env.REACT_APP_POST}`,
        {
          api_name: "GetAll",
          index_name: `${process.env.REACT_APP_ORG}-master-product`,
          key: "productName",
          value,
          customSearch: [{ countryCode: user.countries[0] }],
        }
      );

      const productDetailsByCode = await postApi(
        `${process.env.REACT_APP_POST}`,
        {
          api_name: "GetAll",
          index_name: `${process.env.REACT_APP_ORG}-master-product`,
          key: "productCode",
          value,
          customSearch: [{ countryCode: user.countries[0] }],
        }
      );

      const { data: dataFetchedByName } = productDetailsByName.data;
      const { data: dataFetchedByCode } = productDetailsByCode.data;

      if (
        dataFetchedByName?.result?.length ||
        dataFetchedByCode?.result?.length
      ) {
        let result = [
          ...((dataFetchedByName?.result &&
            dataFetchedByName?.result?.length &&
            dataFetchedByName.result) ||
            []),
          ...((dataFetchedByCode?.result &&
            dataFetchedByCode?.result?.length &&
            dataFetchedByCode.result) ||
            []),
        ];

        return result.reduce(
          (ac, { _source }) => ({ ...ac, [_source.productCode]: _source }),
          {}
        );
      }
      return {};
    } catch (err) {
      console.error(err);
      return {};
    }
  };

  const dataFetch = async () => {
    try {
      setIsLoading(true);
      if (
        formData.duration === "custom" &&
        (!formData.startDate ||
          !formData.endDate ||
          new Date(formData.startDate) > new Date(formData.endDate))
      ) {
      } else {
        let res = await postApi(`${process.env.REACT_APP_POST}`, {
          api_name: apiName,
          org: process.env.REACT_APP_ORG,
          sector: process.env.REACT_APP_SECTOR,
          ...formData,
          products: Object.values(selectedProducts),
          countries: user.countries,
        });

        const { data, status, message } = res.data;
        if (!status) {
          addAlert({ message, type: AlertTypeEnum.ERROR });
        }

        if (Object.keys(selectedProducts).length === 0) {
          setChartData(data);
        } else {
          setChartData(
            data.filter((d) =>
              Object.keys(selectedProducts).includes(d.productCode)
            )
          );
        }
      }
    } catch (err) {
      console.error(err);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    dataFetch();
  }, [onSearch, selectedProducts]);

  return (
    <Box sx={{ flex: 1 }}>
      <Stack direction="row" flexWrap="wrap" columnGap={2} rowGap={3}>
        <ProductMultiSelect
          searchApi={(value) => searchForProduct(value)}
          setValue={setValue}
          selectedProducts={selectedProducts}
          handleSelectedProducts={handleSelectedProducts}
        />
      </Stack>

      <Box sx={{ height: 380, pt: 2, position: "relative" }}>
        {!isLoading ? (
          chartData !== undefined && chartData.length ? (
            <>
              <Typography
                variant="body1"
                align="center"
                sx={{
                  transform: "rotate(-90deg)",
                  transformOrigin: "0 0",
                  position: "absolute",
                  top: 200,
                  left: 0,
                  fontSize: "14px",
                  fontWeight: 600,
                }}>
                {t(label.toUpperCase())}
              </Typography>
              <Box sx={{ height: 330, ml: 4 }} flex={1}>
                <Chart
                  chartType={ChartTypeEnum.LINE}
                  data={productQuantityDataToChartData(
                    chartData,
                    dateFormat,
                    "net" + label
                  )}
                  options={chartOptions}
                />
              </Box>
            </>
          ) : (
            <></>
          )
        ) : (
          <Loader />
        )}
      </Box>
    </Box>
  );
};

export default ProductTrend;
