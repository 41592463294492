import { Container } from "@mui/material";

import SelfServiceDetails from "../components/selfServiceDetails/SelfServiceDetails";

interface ISelfServiceDetailsPageProps {}

const SelfServiceDetailsPage: React.FC<ISelfServiceDetailsPageProps> = () => {
  return (
    <Container maxWidth="xl">
      <SelfServiceDetails />
    </Container>
  );
};

export default SelfServiceDetailsPage;
