/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Close } from "@mui/icons-material";
import { Button, ClickAwayListener, Divider, Drawer, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

import BasicHeader from "../../BasicHeader";
import CustomizeColumns from "./CustomizeColumns";
import { droppableMap } from "../../../transactions/constants";
import { ITableRowFrame } from "../types";

const drawerWidth = "353px";

interface ICustomizeColumnsDrawerProps {
  openCustomizeColumns: boolean;
  setOpenCustomizeColumns: React.Dispatch<React.SetStateAction<boolean>>;
  fixedTableColumn: ITableRowFrame[];
  setFixedTableColumn: React.Dispatch<React.SetStateAction<ITableRowFrame[]>>;
  flexibleTableColumn: ITableRowFrame[];
  setFlexibleTableColumn: React.Dispatch<React.SetStateAction<ITableRowFrame[]>>;
}

const CustomizeColumnsDrawer: React.FC<ICustomizeColumnsDrawerProps> = ({
  openCustomizeColumns,
  setOpenCustomizeColumns,
  fixedTableColumn,
  setFixedTableColumn,
  flexibleTableColumn,
  setFlexibleTableColumn,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index) return;

    let add;
    let fixedList = [...fixedTableColumn];
    let flexibleList = [...flexibleTableColumn];

    if (source.droppableId === "fixed-list") {
      add = fixedList[source.index];
      fixedList = [...fixedList.slice(0, source.index), ...fixedList.slice(source.index + 1)];
    } else {
      add = flexibleList[source.index];
      flexibleList = [...flexibleList.slice(0, source.index), ...flexibleList.slice(source.index + 1)];
    }

    if (destination.droppableId === "fixed-list") {
      fixedList = [
        ...fixedList.slice(0, destination.index),
        // Column visibility set true on Fixed List
        { ...add, isVisible: true },
        ...fixedList.slice(destination.index),
      ];
    } else {
      flexibleList = [...flexibleList.slice(0, destination.index), add, ...flexibleList.slice(destination.index)];
    }

    setFlexibleTableColumn(flexibleList);
    setFixedTableColumn(fixedList);
  };

  return (
    <ClickAwayListener onClickAway={() => setOpenCustomizeColumns(false)}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            padding: "24px",
          },
        }}
        variant="persistent"
        anchor="right"
        open={openCustomizeColumns}
      >
        <BasicHeader title="Customize Columns" customStyle={{ paddingBottom: "24px" }}>
          <IconButton onClick={() => setOpenCustomizeColumns(false)}>
            <Close
              sx={{
                height: "18px",
                width: "18px",
                color: `${theme.palette.primary.main}`,
              }}
            />
          </IconButton>
        </BasicHeader>
        <Typography variant="h3" sx={{ fontWeight: "normal" }}>
          Drag columns to rearrange,click on visibility icon to hide/show.
        </Typography>
        <DragDropContext onDragEnd={onDragEnd}>
          {droppableMap.map(({ droppableId, titleHeader }) => (
            <Droppable droppableId={droppableId} key={droppableId}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <Typography
                    variant="h2"
                    sx={{
                      padding: "24px 0px 16px 0px",
                    }}
                  >
                    {titleHeader}
                  </Typography>
                  <CustomizeColumns
                    droppableId={droppableId}
                    fixedTableColumn={fixedTableColumn}
                    flexibleTableColumn={flexibleTableColumn}
                    setFlexibleTableColumn={setFlexibleTableColumn}
                  />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </DragDropContext>
        <Divider />
        <Stack
          direction="row"
          spacing="16px"
          sx={{
            justifyContent: "flex-end",
            paddingTop: "20px",
          }}
        >
          <Button variant={"buttonOutlined"} children={t("RESET")} />
          <Button variant={"buttonContained"} children={"CONFIRM"} />
        </Stack>
      </Drawer>
    </ClickAwayListener>
  );
};

export default CustomizeColumnsDrawer;
