import { INavItem } from "./NavItems";

interface INavigationData extends INavItem {
  label: string;
  path: string;
  subNavigation?: INavItem[];
  expect?: string[];
}

export const navigationData: INavigationData[] = [
  {
    label: "Home",
    path: "/",
  },
  // {
  //   label: "Home",
  //   path: "/",
  // },
  // {
  //   label: "Dashboard",
  //   path: "/Home",
  // },
  // {
  //   label: "Self Service",
  //   path: "/self-service",
  //   subNavigation: [
  //     {
  //       label: "SAP transactions",
  //       path: "/self-service/sap",
  //     },
  //     {
  //       label: "JJCC transactions",
  //       path: "/self-service/jjcc",
  //     },
  //     // {
  //     //   label: "Configuration settings",
  //     //   path: "/self-service/configuration",
  //     // },
  //   ],
  // },
  // {
  //   label: "Integration Catalog",
  //   path: "/catalog",
  // },
  // {
  //   label: "Insights",
  //   path: "/insights",
  // },
];
