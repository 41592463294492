import { ActiveElement, Chart, ChartEvent } from "chart.js";
import { NavigateFunction } from "react-router-dom";
import { ChartTypeEnum } from "../common/chart/constants";
import { modifyBarChartData } from "../common/chart/helper";
import { StatusTypeEnum } from "../common/table/types";
import { barDataSet, barOptions, lineDataSet, lineOptions, overallReportsConfig } from "./constants";
import { DocumentResponse, DayType, EOverallReportsConfig } from "./types";

interface ExtendedChartEvent extends ChartEvent {
  chart: Chart;
}

export const formatDocument = (response: DocumentResponse, value: DayType, navigate: NavigateFunction) => {
  return Object.keys(response.fields).map((e) => {
    const chartType = e === "sales" ? ChartTypeEnum.LINE : ChartTypeEnum.BAR;

    return {
      orderAmounts: response.fields[e].map((ele: EOverallReportsConfig) => {
        let tempTitleValue = overallReportsConfig[ele];

        if (overallReportsConfig[ele] === "total this ") {
          if (overallReportsConfig[ele] === "total this " && value === DayType.TODAY) {
            tempTitleValue = `today's total`;
          } else if (overallReportsConfig[ele] === "total this " && value === DayType.WEEK) {
            tempTitleValue = `total this week`;
          } else if (overallReportsConfig[ele] === "total this " && value === DayType.THREE_MONTHS) {
            tempTitleValue = `3 months total`;
          } else if (overallReportsConfig[ele] === "total this " && value === DayType.MONTH) {
            tempTitleValue = `6 months total`;
          } else {
            tempTitleValue = overallReportsConfig[ele];
          }
        }
        return {
          title: tempTitleValue,
          amount: Number(response.sales.reduce((ac: number, cv: any) => (ac += +cv[ele]), 0)).toFixed(0),
        };
      }),

      chart: {
        chartType,
        data: modifyBarChartData({
          data: response.sales,
          dataSet: chartType === ChartTypeEnum.LINE ? lineDataSet : barDataSet,
          dayType: value || DayType.DAY,
          dataKey: chartType === ChartTypeEnum.LINE ? response.fields[e] : response.fields[e].slice(0, 2),
          backgroundColor: chartType === ChartTypeEnum.LINE ? ["#0025A5A5"] : ["#ff1100", "#1E22AA"],
        }),
        options:
          chartType === ChartTypeEnum.LINE
            ? lineOptions
            : {
                ...barOptions,
                onClick: (event: ExtendedChartEvent, ele: ActiveElement[], c: Chart, d: any) => {
                  let processedDate = "";
                  let startDate = "";
                  let endDate = "";
                  if (value === DayType.WEEK || value === DayType.TODAY) {
                    let dateToProcess = String(event.chart.data.labels?.[ele[0]?.index]);
                    let [date, month, year] = dateToProcess.split("/");
                    processedDate = `${year}-${month}-${date}`;
                  } else if (value === DayType.MONTH || value === DayType.THREE_MONTHS) {
                    let monthToProcess = String(event.chart.data.labels?.[ele[0]?.index]);
                    let year = new Date().getFullYear();
                    let indexOfMonth = new Date(monthToProcess + "1," + year).getMonth() + 1;
                    let lastDateOfTheMonth = new Date(year, indexOfMonth, 0).getDate();
                    let temporaryStartDate = `${year}-${indexOfMonth}-1`;
                    year = new Date(temporaryStartDate).getTime() > new Date().getTime() ? year - 1 : year;

                    startDate = `${year}-${indexOfMonth}-1`;
                    endDate = `${year}-${indexOfMonth}-${lastDateOfTheMonth}`;
                  }

                  navigate("/self-service/sap", {
                    state: {
                      gStatus: ele[0]?.datasetIndex ? StatusTypeEnum.SUCCESS : StatusTypeEnum.ERROR,
                      graph: e,
                      fromOrderDate:
                        ((value === DayType.WEEK || value === DayType.TODAY) && processedDate) ||
                        ((value === DayType.MONTH || value === DayType.THREE_MONTHS) && startDate),
                      toOrderDate:
                        ((value === DayType.WEEK || value === DayType.TODAY) && processedDate) ||
                        ((value === DayType.MONTH || value === DayType.THREE_MONTHS) && endDate),
                      status: ele[0]?.datasetIndex === 0 ? "ERROR" : "SUCCESS",
                    },
                  });
                },
              },
      },
    };
  });
};
