import React from "react";
import { Toolbar } from "@mui/material";

import NavBar from "../components/navbar/NavBar";
import Footer from "../components/footer/Footer";
import { IChildrenProps } from "../components/common/types";

export const PublicRouteWrapper: React.FC<IChildrenProps> = ({ children }) => {
  return (
    <>
      <NavBar isPrivate={false} />
      <Toolbar sx={{ minHeight: "76px !important" }} />

      {children}
      <Footer />
    </>
  );
};

export default PublicRouteWrapper;
