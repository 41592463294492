import React, { useState, useEffect } from "react";
import { Box, Tab, Tabs, styled } from "@mui/material";
import { StyledTabs } from "./types";
import store from "../common/GlobalStore";

store.setState("tabIndex", 0);

const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  "aria-controls": `simple-tabpanel-${index}`,
});

interface IBasicTabsProps {
  data: StyledTabs[];
  components: React.ReactNode[];
  defaultTab?: number;
  currentTab?: number;
  setCurrentTab?: (idx: number) => void;
}

const CustomTab = styled((props: StyledTabs) => <Tab disableRipple {...props} iconPosition="start" />)(({ theme }) => ({
  fontWeight: 600,
  lineHeight: "18px",
  fontSize: "14px",
  fontFamily: "'JJCircularMedium','Helvetica', 'Arial', sans-serif",
  color: theme.palette.text.secondary,
  textTransform: "capitalize",
  paddingTop: "7px",
  paddingBottom: "6px",
  minHeight: "unset",
  maxHeight: "32px",
  borderBottom: `2.3px solid ${theme.palette.text.disabled}`,
  "&:hover": {
    color: theme.palette.text.primary,
  },
  "&.Mui-selected": {
    color: theme.palette.text.primary,
  },
}));

const BasicTabs: React.FC<IBasicTabsProps> = ({ data, components, defaultTab = 0, currentTab = 0, setCurrentTab }) => {
  const [, setCurrentTabNum] = store.useState<number>("tabIndex");
  const [value, setValue] = useState<number>(defaultTab);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    data.forEach((e) => {
      if (e.label === "order" || e.label === "delivery" || e.label === "invoice" || e.label === "sales") {
        setValue(newValue);
        setCurrentTab && setCurrentTab(newValue);
        setCurrentTabNum(newValue);
      }
      setValue(newValue);
      setCurrentTab && setCurrentTab(newValue);
    });
  };

  useEffect(() => {
    setValue(currentTab);
  }, [currentTab]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ mb: 3, mt: 1.5 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{ minHeight: "32px", height: "32px" }}
        >
          {data.map(({ label, icon, disabled }, i) => (
            <CustomTab icon={icon} label={label} {...a11yProps(i)} key={i} disabled={disabled} />
          ))}
        </Tabs>
      </Box>

      {components.map((children, index) => (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          key={index}
        >
          {value === index && children}
        </div>
      ))}
    </Box>
  );
};

export default BasicTabs;
