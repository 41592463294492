import React from "react";
import { Paper, SxProps, Typography } from "@mui/material";
import { TrendingDown, TrendingUp } from "@mui/icons-material";

interface ITrendInfoCardProps {
  isTrendingUp: boolean;
  content: string;
  value?: string;
  customStyle?: SxProps;
}

const TrendInfoCard: React.FC<ITrendInfoCardProps> = ({ isTrendingUp, content, value, customStyle = {} }) => {
  return (
    <Paper variant="outlined" sx={{ display: "flex", my: 2, p: 2, gap: 2, ...customStyle }}>
      {isTrendingUp ? <TrendingUp color="success" /> : <TrendingDown color="error" />}
      <Typography variant="body1" flex={1}>
        {content}
      </Typography>

      {value ? (
        <Typography variant="h2" color="#000">
          {value}
        </Typography>
      ) : (
        <></>
      )}
    </Paper>
  );
};

export default TrendInfoCard;
