import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Make sure to import UTC plugin
import timezone from "dayjs/plugin/timezone"; // Import timezone plugin
import { chartColors } from "../common/chart/constants";

// Load the plugins
dayjs.extend(utc);
dayjs.extend(timezone);
const localTimezone = dayjs.tz.guess(); // Get the local timezone

export const productQuantityDataToChartData = (
  data,
  dateFormat,
  key: string
) => {
  // console.log(`Full Timezone Name: ${dayjs.tz.guess()}`);
  return {
    labels: data[0].points.map(
      ({ startDate, endDate }) =>
        `${dayjs.tz(startDate, localTimezone).format(dateFormat)} to ${dayjs
          .tz(endDate, localTimezone)
          .format(dateFormat)}`
    ),
    datasets: data.map(({ points, productName }, index) => ({
      label: productName,
      data: points.map((point) => point?.[key]),
      fill: false,
      borderWidth: 3,
      borderColor: chartColors[index],
      lineTension: 0.1,
      pointBackgroundColor: "#0000",
      backgroundColor: "#0000",
    })),
  };
};

export const purchaseOrderDataToChartData = (
  data: { startDate: string; endDate: string; value: number }[],
  dateFormat
) => ({
  labels: data.map(
    ({ startDate, endDate }) =>
      `${dayjs.tz(startDate, localTimezone).format(dateFormat)} to ${dayjs
        .tz(endDate, localTimezone)
        .format(dateFormat)}`
  ),
  datasets: [
    {
      label: "Order",
      data: data.map(({ value }) => value),
      fill: false,
      borderWidth: 3,
      borderColor: chartColors[0],
      lineTension: 0.1,
      pointBackgroundColor: "#0000",
      backgroundColor: "#0000",
    },
  ],
});
