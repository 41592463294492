import React from "react";
import { Box, CircularProgress } from "@mui/material";

const Loader = () => {
  return (
    <Box sx={{ width: 1 }}>
      <Box sx={{ margin: "auto", width: 50 }}>
        <CircularProgress />
      </Box>
    </Box>
  );
};

export default Loader;
