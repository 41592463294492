import { countryDetails } from "../common/constants/countries";
import { ITableRowFrame } from "../common/table/types";
import { IServiceList } from "../transactions/types";
import { ITransactionDetailsElements } from "./types";

const orderIncompleteStatusList = [
  { value: "Incomplete", label: "Incomplete" },
  { value: "Pending", label: "Pending" },
  { value: "C", label: "C" },
  { value: "I", label: "I" },
  { value: "A", label: "A" },
];

const rejectionStatusList = [
  { value: "A", label: "A" },
  { value: "B", label: "B" },
  { value: "C", label: "C" },
];

const creditStatusList = [
  { value: "Order Blocked", label: "Order Blocked" },
  { value: "Credit Hold", label: "Credit Hold" },
];

const orderTypeList = [
  { value: "Standard Order (OR)", label: "Standard Order (OR)" },
  { value: "Consignment  Fill-Up (KB)", label: "Consignment  Fill-Up (KB)" },
  { value: "Consignment Issue (KE)", label: "Consignment Issue (KE)" },
  { value: "Consignment Pick-up (KA)", label: "Consignment Pick-up (KA)" },
  { value: "Auto Indiv. PO Order (ZTAB)", label: "Auto Indiv. PO Order (ZTAB)" },
  { value: "Billing with stock (9SIS)", label: "Billing with stock (9SIS)" },
  { value: "Consignment Returns (KR)", label: "Consignment Returns (KR)" },
  { value: "Contract-Deferred Rev (ZCO2)", label: "Contract-Deferred Rev (ZCO2)" },
  { value: "Credit Memo Request (CR)", label: "Credit Memo Request (CR)" },
  { value: "Debit Memo Request (DR)", label: "Debit Memo Request (DR)" },
  { value: "Donation-Issue (ZDO)", label: "Donation-Issue (ZDO)" },
  { value: "Free-of-charge Order (ZFOC)", label: "Free-of-charge Order (ZFOC)" },
  { value: "Loan Kit Rtns/Credit (ZKR)", label: "Loan Kit Rtns/Credit (ZKR)" },
  { value: "Loan Kits-Charge (ZKE)", label: "Loan Kits-Charge (ZKE)" },
  { value: "Loan Kits-Despatch (ZKB)", label: "Loan Kits-Despatch (ZKB)" },
  { value: "Loan Kits-Pick-up (ZKA)", label: "Loan Kits-Pick-up (ZKA)" },
  { value: "Loaner dispatch (9SE2)", label: "Loaner dispatch (9SE2)" },
  { value: "Loaner return (9LA)", label: "Loaner return (9LA)" },
  { value: "Returns (RE)", label: "Returns (RE)" },
  { value: "Samples-Issue (ZSP)", label: "Samples-Issue (ZSP)" },
  { value: "Samples-Returns (ZSPR)", label: "Samples-Returns (ZSPR)" },
  { value: "Sell Loaner (9SE5)", label: "Sell Loaner (9SE5)" },
];

const invoiceStatusList = [
  { value: "Invoiced", label: "Invoiced" },
  { value: "Partially Invoiced", label: "Partially Invoiced" },
  { value: "Credited", label: "Credited" },
  { value: "Debited", label: "Debited" },
  { value: "A", label: "A" },
  { value: "B", label: "B" },
  { value: "C", label: "C" },
];

const overallStatusList = [
  { value: "Order Accepted", label: "Order Accepted" },
  { value: "InProgress", label: "InProgress" },
  { value: "Invoiced", label: "Invoiced" },
  { value: "Completed", label: "Completed" },
  { value: "Cancelled", label: "Cancelled" },
  { value: "Returned", label: "Returned" },
  { value: "Order Blocked", label: "Order Blocked" },
];

const deliveryStatusList = [
  { value: "Pick pack", label: "Pick pack" },
  { value: "Partial Pick pack", label: "Partial Pick pack" },
  { value: "Shipped", label: "Shipped" },
  { value: "Partially Shipped", label: "Partially Shipped" },
];

export const orderFixedHeadCells: ITableRowFrame[] = [{ name: "sapOrderlineNumber", label: "SAP Order Line No." }];

export const orderHeadCells: ITableRowFrame[] = [
  { name: "itemCategory", label: "Item Category" },
  { name: "higherLevelItemNo", label: "High Level Item No." },
  { name: "lineStatus", label: "Line Status" },
  { name: "quantity", label: "Order Qty." },
  { name: "salesUom", label: "Sales UOM" },
  { name: "backorderQty", label: "Backordered Qty." },
  { name: "reasonForRejection", label: "Reason For Rejection" },
  { name: "rejectReason", label: "Reject Reason" },
  { name: "invoiceQty", label: "Invoice Qty" },
  { name: "invoiceStatus", label: "Invoice Status" },
  { name: "returnInvNumber", label: "Return Invoice No." },
  { name: "gtin", label: "GTIN" },
  { name: "gtinEntered", label: "GTIN Entered" },
  { name: "poLineItemNum", label: "P.O. Line Item No." },
  { name: "contractNum", label: "Contract No." },
  { name: "entryNumber", label: "Batch No." },
  { name: "batchExpiry", label: "Batch Expiry" },
  { name: "materialEntered", label: "Material Entered" },
  { name: "materialNumber", label: "Material No." },
  { name: "matAvailDate", label: "Material Avail. Date" },
  { name: "referencedVariant_code", label: "Reference Doc. No." },
  { name: "productDivision", label: "Product Division" },
  { name: "route", label: "Route" },
  { name: "shipToCustNum", label: "Ship To Customer No." },
  { name: "deliveryStatus", label: "Delivery Status" },
  { name: "deliveryBlock", label: "Delivery Line Block" },
  { name: "deliveryDate", label: "Delivery Date" },
  { name: "scheduleShipDate", label: "Schedule Ship date" },
  { name: "confirmedQty", label: "Confirmed Qty." },
  { name: "deliverQty", label: "Delivered Qty." },
  { name: "pricingStatus", label: "Price Status" },
  { name: "billingDate", label: "Billing Date" },
  { name: "plantCode", label: "Plant Code" },
  { name: "plantName", label: "Plant Name" },
];

// Temporary constant until api get ready
export const orderedFormList: IServiceList[][] = [
  [
    { name: "sapOrderNumber", type: "text", label: "SAP Order No.", placeholder: "Enter your number" },
    {
      name: "hybrisOrderNumber",
      type: "text",
      label: "Hybris Order No.",
      placeholder: "Enter your number",
    },
    {
      name: "date",
      type: "date",
      label: "Order Created Date",
      placeholder: "Enter your number",
    },
    { name: "orderType", type: "text", label: "Order Type" },
    { name: "reasonCode", type: "text", label: "Order Reason Code", placeholder: "Enter your number" },
    { name: "businessSector", type: "text", label: "Business Sector" },
    { name: "sourceSystemId", type: "text", label: "Source System ID" },
    { name: "invoiceStatus", type: "dropdown", label: "Invoice Status", lists: invoiceStatusList },
    { name: "overAllStatus", type: "dropdown", label: "Overall Status", lists: overallStatusList },
    {
      name: "orderIncompleteStatus",
      type: "dropdown",
      label: "Order Incomplete Status",
      lists: orderIncompleteStatusList,
    },
    {
      name: "rejectionStatus",
      type: "dropdown",
      label: "Rejection Status",
      lists: rejectionStatusList,
    },
  ],
  [
    { name: "Unit_uid", type: "text", label: "Sold To Account ID" },
    { name: "soldtoname", type: "text", label: "Sold To Account Name" },
    { name: "billToAccount", type: "text", label: "Bill To Account ID" },
    { name: "billToName", type: "text", label: "Bill To Account Name" },
    { name: "creditStatus", type: "dropdown", label: "Credit Status", lists: creditStatusList },
    { name: "priceDate", type: "date", label: "Price Date" },
    {
      name: "currency",
      type: "dropdown",
      label: "Currency",
      lists: Object.values(countryDetails).map(({ countryCode, currency }) => ({
        value: currency,
        label: countryCode,
      })),
    },
    { name: "paymentRemarks", type: "text", label: "Payment Remarks" },
    { name: "billBlock", type: "text", label: "Bill Block" },
  ],
  [
    {
      name: "shipToAccount",
      type: "text",
      label: "Ship To Account ID",
    },
    {
      name: "shipToName",
      valueKey: "shipToName",
      type: "text",
      label: "Ship To Account Name",
    },
    {
      name: "deliveryAddress_streetname",
      type: "text",
      label: "Ship To Address",
      width: 8,
      breakk: 16,
    },
    { name: "deliveryAddress2", type: "text", width: 8, breakk: 16 },
    {
      name: "country",
      type: "dropdown",
      label: "Country",
      lists: Object.values(countryDetails).map(({ countryCode, name }) => ({ value: countryCode, label: name })),
      breakk: 16,
      width: 8,
    },
    { name: "deliveryAddress_region_isocode", type: "text", label: "State" },
    { name: "deliveryAddress_postalcode", type: "text", label: "Postal Code", breakk: 12, width: 5.33 },
    { name: "requestedDeliveryDate", type: "date", label: "Requested Delivery Date" },
    { name: "delStatus", type: "dropdown", label: "Delivery Status", lists: deliveryStatusList },
    { name: "deliveryBlock", type: "text", label: "Delivery Block" },
  ],
  [
    { name: "poOrder", type: "text", label: "P.O. Order No." },
    { name: "poType", type: "text", label: "P.O. Type" },
    { name: "shipToPo", type: "text", label: "Ship To P.O. No." },
  ],
  [
    { name: "salesOrganization", type: "text", label: "Sales Org." },
    { name: "division", type: "text", label: "Division" },
    { name: "distributionChannel", type: "text", label: "Distribution Channel" },
  ],
  [
    { name: "salesAgent", type: "text", label: "Sales Agent" },
    { name: "caseDate1", type: "date", label: "Case Date" },
    { name: "surgeryNumber", type: "text", label: "Surgery No." },
    { name: "surgeryInfo_operationType", valueKey: "code", type: "text", label: "Operation Type" },
    { name: "surgeonId", type: "text", label: "Surgeon ID" },
    { name: "surgeonName", type: "text", label: "Surgeon Name" },
    { name: "patientId", type: "text", label: "Patient ID" },
    { name: "patientName", type: "text", label: "Patient Name" },
    { name: "surgeryInfo_operationalDetails", type: "text", label: "Operation Details" },
    { name: "surgeryInfo_operationNotes", type: "text", label: "Operation Notes" },
  ],
];

export const deliveryFixedHeadCells: ITableRowFrame[] = [{ name: "deliveryNum", label: "Delivery No." }];

export const deliveryHeadCells: ITableRowFrame[] = [
  { name: "deliveryLineNum", label: "Delivery Line Item No." },
  { name: "deliveryQty", label: "Delivery Qty " },
  { name: "actualShipDate", label: "Actual Ship date" },
  { name: "matAvailableDate", label: "Mat Available Date" },
  { name: "actualGoodMovmntDate", label: "Actl. Goods Movement Date" },
  { name: "actualGoodMovmntTime", label: "Actl. Goods Movement Time" },
  { name: "sapOrderNumber", label: "Sap Order No." },
  { name: "orderLineNum", label: "Order Line No." },
  { name: "orderQuantity", label: "Order Qty." },
  { name: "totalWeight", label: "Total Weight" },
  { name: "noCartoons", label: "No. Of Cartons" },
  { name: "podDate", label: "Po date" },
  { name: "baseUOM", label: "Base UOM" },
  { name: "batchNum", label: "Batch No." },
  { name: "batchExpiryDate", label: "Batch Exp. Date" },
  { name: "batchSplitQty", label: "Batch Split Qty." },
  { name: "serialNum", label: "Serial No." },
  { name: "manufactureDate", label: "Manufacture Date" },
  { name: "pickingStatus", label: "Picking Status" },
];

export const deliveryFormList: IServiceList[][] = [
  [
    { name: "deliveryNum", type: "text", label: "Delivery No.", placeholder: "Enter your number" },
    { name: "orderNum", type: "text", label: "Sap Order No.", placeholder: "Enter your number" },
    { name: "pickSlipNum", type: "text", label: "Pick Slip No.", placeholder: "Enter your number" },
    { name: "TrackingNum", type: "text", label: "Tracking No" },
    { name: "sourceSystemId", type: "text", label: "Source System ID" },
    { name: "trackingUrl", type: "dropdown", label: "Tracking URL" },
    { name: "businessSector", type: "dropdown", label: "Business Sector" },
    { name: "sourceSystemId", type: "text", label: "Source System ID" },
  ],
  [
    { name: "carrierCode", type: "text", label: "Carrier Code" },
    { name: "carrierName", type: "text", label: "Carrier Name" },
    { name: "transportType", type: "text", label: "Transport Type" },
    { name: "transportTypeDescription", type: "text", label: "Transport Type Description" },
  ],
];

export const invoiceFixedHeadCells: ITableRowFrame[] = [
  { name: "referenceSalesOrderNumber", label: "Reference Sales Order No." },
];

export const invoiceHeadCells: ITableRowFrame[] = [
  { name: "referenceSalesOrderItemNumber", label: "Reference Sales Order Item No." },
  { name: "invoiceDocumentNumber", label: "Invoice Doc. No." },
  { name: "originalInvoiceNumber", label: "Original Invoice No." },
  { name: "itemNo", label: "Item No." },
  { name: "material", label: "Material" },
  { name: "materialEntered", label: "Material Entered" },
  { name: "highLevelLineItemNumber", label: "High Level Line Item No." },
  { name: "actualInvoicedQuantity", label: "Act. Invoiced Qty" },
  { name: "netValue", label: "Net Value" },
  { name: "productDivision", label: "Product Division" },
  { name: "plant", label: "Plant" },
  { name: "salesUOM", label: "Sales UOM" },
  { name: "referenceDeliveryDoc", label: "Ref. Delivery Doc." },
  { name: "referenceDeliveryLineItemNumber", label: "Ref. Delivery Line Item No." },
  { name: "lineItemTaxAmount", label: "Line Item Tax Amt." },
  { name: "sourceSystemId", label: "Source System Id" },
  { name: "recordItemTime", label: "Timestamp" },
];

export const invoiceFormList: IServiceList[][] = [
  [
    {
      name: "invoiceDocumentNumber",
      type: "text",
      label: "Invoice Doc. No.",
      placeholder: "Enter your number",
    },
    {
      name: "referenceOrderNumber",
      type: "text",
      label: "Reference Order No.",
      placeholder: "Enter your number",
    },
    { name: "recordItemTime", type: "date", label: "Timestamp", placeholder: "Enter your number" },
    { name: "sourceSystemId", type: "text", label: "Source System Id" },
  ],
  [
    { name: "soldToAccount", type: "text", label: "Sold To Account ID" },
    { name: "soldToName", type: "text", label: "Sold To Account Name" },
    { name: "billToAccount", type: "text", label: "Bill To Account ID" },
    { name: "billToName", type: "text", label: "Bill To Account Name" },
    { name: "payer", type: "text", label: "Payer" },
    { name: "record_date_time", type: "date", label: "Billing Document Date" },
    { name: "billingType", type: "text", label: "Billing Type" },
    { name: "dueDate", type: "date", label: "Due Date" },
    { name: "currency", type: "text", label: "Currency" },
    { name: "netValueInDocumentCurrency", type: "text", label: "Net Value In Document Currency" },
  ],
  [
    {
      name: "shipToAccount",
      valueKey: "deliveryAddress.jnJAddressId",
      type: "text",
      label: "Ship To Account ID",
    },
    {
      name: "shipToName",
      valueKey: "deliveryAddress.firstname",
      type: "text",
      label: "Ship To Account Name",
    },
    {
      name: "deliveryAddress",
      valueKey: "deliveryAddress.streetname",
      type: "text",
      label: "Ship To Address",
      width: 8,
      breakk: 16,
    },
    { name: "deliveryAddress2", valueKey: "deliveryAddress.line2", type: "text", width: 8, breakk: 16 },
    {
      name: "country",
      valueKey: "deliveryAddress.country.isocode",
      type: "dropdown",
      label: "Country",
      lists: Object.values(countryDetails).map(({ countryCode, currency }) => ({
        value: countryCode,
        label: currency,
      })),
      breakk: 16,
      width: 8,
    },
    { name: "state", type: "text", label: "State" },
    { name: "postalcode", type: "text", label: "Postal Code", breakk: 12, width: 5.33 },
  ],
  [
    { name: "poOrder", type: "text", label: "Customer P.O. Order No." },
    { name: "poType", type: "text", label: "P.O. Type" },
    { name: "shipToPo", type: "text", label: "Ship To P.O. No." },
  ],
  [
    { name: "salesOrganization", type: "text", label: "Sales Org." },
    { name: "division", type: "text", label: "Division" },
    { name: "distributionChannel", type: "text", label: "Distribution Channel" },
  ],
];

export const jjccFixedHeadCells: ITableRowFrame[] = [{ name: "lineItemNo", label: "Line Item No." }];

export const jjccHeadCells: ITableRowFrame[] = [
  { name: "materialEntered", label: "Material Entered" },
  { name: "materialNumber", label: "Material No." },
  { name: "materialNumberofSubstitute", label: "Material No. of Substitute" },
  { name: "reasonforMaterialSubstitution", label: "Material Substitute Reason" },
  { name: "higherLevelItemNumber", label: "High Level Item No." },
  { name: "batchNumber", label: "Batch No." },
  { name: "serialNumber", label: "Serial No." },
  { name: "orderQuantity", label: "Order Qty" },
  { name: "itemCategory", label: "Item Category." },
  { name: "salesUnit", label: "Sales Unit" },
  { name: "poItemNumber", label: "PO. Item No." },
  { name: "route", label: "Route" },
  { name: "unloadingPoint", label: "Unloading Point" },
  { name: "shippingPoint", label: "Shipping Point" },
  { name: "plant", label: "Plant" },
];

export const jjccFormList: IServiceList[][] = [
  [
    { name: "orderType", type: "text", label: "Order Type.", placeholder: "Enter your number" },
    {
      name: "orderReason",
      type: "text",
      label: "Order Reason Code",
      placeholder: "Enter your number",
    },
    {
      name: "source",
      type: "text",
      label: "Source System ID",
      placeholder: "Enter your number",
    },
    { name: "autoReplDec", type: "dropdown", label: "Auto Rep ID", lists: orderTypeList },
    { name: "orderRemarks", type: "text", label: "Order Remarks", placeholder: "Enter your number" },
    { name: "invoiceRemarks", type: "text", label: "Invoice Remarks" },
  ],
  [
    { name: "soldToNumber", type: "text", label: "Sold To Account" },
    { name: "billToNumber", type: "text", label: "Bill To Account" },
    { name: "payer", type: "text", label: "Payer" },
  ],
  [
    {
      name: "shipToNumber",
      valueKey: "deliveryAddress.jnJAddressId",
      type: "text",
      label: "Ship To Account",
    },
    {
      name: "requestedDeliveryDate",
      valueKey: "deliveryAddress.country.isocode",
      type: "date",
      label: "Requested Delivery Date",
      breakk: 16,
      width: 8,
    },
    { name: "deliveryBlock", type: "text", label: "Delivery Block" },
    { name: "shippingConditions", type: "text", label: "Shipping Conditions", breakk: 12, width: 5.33 },
    { name: "storageLocation", type: "date", label: "Storage Locations" },
    { name: "stockUserNumber", type: "dropdown", label: "Stock User No.", lists: deliveryStatusList },
    { name: "endUserNumber", type: "text", label: "End User NO." },
    { name: "contractNumber", type: "text", label: "Contact No." },
    { name: "gatewayOrderNumber", type: "text", label: "Gateway Order No." },
  ],
  [
    { name: "purchaseOrderNumber", type: "text", label: "P.O. Order No." },
    { name: "purchaseOrderType", type: "text", label: "P.O. Type" },
    { name: "purchaseOrderDate", type: "text", label: "P.O. Date" },
  ],
  [
    { name: "salesOrg", type: "text", label: "Sales Org." },
    { name: "division", type: "text", label: "Division" },
    { name: "distributionChannel", type: "text", label: "Distribution Channel" },
    { name: "languageKey", type: "text", label: "Language Key" },
    { name: "commitFlag", type: "text", label: "Commit Flag" },
  ],
  [
    { name: "salesAgentNumber", type: "text", label: "Sales Agent" },
    { name: "salesOffice", type: "text", label: "Sales Office" },
    { name: "caseType", type: "text", label: "Case Type" },
    { name: "operationType", type: "text", label: "Operation Type" },
    { name: "operationDate", type: "date", label: "Operation Date" },
    { name: "surgeonNumber", type: "text", label: "Surgeon ID" },
    { name: "surgeonCustomerNumber", type: "text", label: "Surgeon Customer No." },
    { name: "patientNumber", type: "text", label: "Patient ID" },
    { name: "operationDetails1", type: "text", label: "Operation Details", width: 8, breakk: 16 },
    { name: "operationDetails2", type: "text", width: 8, breakk: 16 },
  ],
];

// TEMP constant
export const amountTotal = [
  { label: "AMOUNT(AUD)", value: "7,529,160" },
  { label: "TAXES(AUD)", value: "752,916" },
  { label: "TOTAL AMOUNT(AUD)", value: "8,282,076" },
];

export const pharmaOrdersFormList: IServiceList[][] = [
  [
    { name: "invoiceStatus", label: "Billing status", type: "text" },
    { name: "sourceSystemId", label: "system id", type: "text" },
    { name: "sapOrderNumber", label: "Sales Document", type: "text" },
    { name: "purchaseOrder", label: "customer Purchase Order", type: "text" },
    { name: "orderType", label: "Sales Document Type", type: "text" },
    { name: "shipToCustomerNumber", label: "Ship to Number", type: "text" },
    { name: "shipToName", label: "Ship to party name", type: "text" },
    { name: "shipToName", label: "Ship to party name 2", type: "text" },
    { name: "shipState", label: "region", type: "text" },
    { name: "shipCountry", label: "Country", type: "text" },
    { name: "shipPostalCode", label: "Postal Code", type: "text" },
    { name: "soldToAccount", label: "Sold to Number", type: "text" },
    { name: "soldtoname", label: "Sold to party name", type: "text" },
    { name: "shipToName", label: "Sold to party name2", type: "text" },
    { name: "shipStreet1", label: "Street", type: "text" },
    { name: "shipStreet2", label: "Street 2", type: "text" },
    { name: "billToAccount", label: "Bill to Number", type: "text" },
    { name: "reqDeliveryDate", label: "Requested Delivery Date", type: "date" },
    { name: "currency", label: "Document Currency", type: "text" },
    { name: "salesOrg", label: "Sales Org", type: "text" },
    { name: "distributionChannel", label: "Distribution Channel", type: "text" },
    { name: "division", label: "Division", type: "text" },
    { name: "overAllStatus", label: "overall status", type: "text" },
    { name: "deliveryStatus", label: "Delivery status", type: "text" },
    { name: "rejectionStatus", label: "Overall rejection status", type: "text" },
    { name: "creditStatus", label: "credit status", type: "text" },
    { name: "orderIncompleteStatus", label: "incompletion status", type: "text" },
    { name: "deliveryBlock", label: "header delivery block", type: "text" },
    { name: "poType", label: "Purchase Order Type", type: "text" },
    { name: "orderReasonCode", label: "Order reason", type: "text" },
    { name: "orderReasonCode", label: "return reference", type: "text" },
    { name: "billBlock", label: "header billing block", type: "text" },
    { name: "priceDate", label: "pricing date", type: "date" },
  ],
];

export const pharmaOrderHeadCells: ITableRowFrame[] = [
  { name: "plantCode", label: "Plant", type: "text" },
  { name: "plantName", label: "Plant name", type: "text" },
  { name: "invoiceStatus", label: "item Invoice status", type: "text" },
  { name: "billingDate", label: "billing date", type: "date" },
  { name: "sapOrderLineNumber", label: "[unique=true]	Line item number", type: "text" },
  { name: "materialNumber", label: "Material Number", type: "text" },
  { name: "salesUom", label: "sales unit", type: "text" },
  { name: "qty", label: "cumulative order quantity", type: "text" },
  { name: "invoicedQty", label: "Invoiced quantity", type: "text" },
  { name: "backorderedQty", label: "Backordered quantity", type: "text" },
  { name: "deliveryBlock", label: "item delivery block ", type: "text" },
  { name: "billingBlock", label: "item billing block", type: "text" },
  { name: "priceStatus", label: "Pricing incompletion status", type: "text" },
  { name: "currency", label: "Document Currency", type: "text" },
  { name: "overAllStatus", label: "item overall status", type: "text" },
  { name: "deliveryStatus", label: "item delivery status", type: "text" },
  { name: "rejectReason", label: "item rejection status", type: "text" },
  { name: "itemCategory", label: " item category", type: "text" },
  { name: "highLevelItemNumber", label: "HL item number", type: "text" },
  { name: "materialEntered", label: "material entered", type: "text" },
  { name: "batchNumber", label: "Batch Number", type: "text" },
  { name: "batchExpiry", label: "batch expiry date", type: "date" },
  { name: "productDivision", label: "Division", type: "text" },
  { name: "route", label: "Route", type: "text" },
  { name: "returnInvNumber", label: "reference document number", type: "text" },
  { name: "gtin", label: "GTIN (International article number)", type: "text" },
  { name: "poLineNum", label: "PO line item number", type: "text" },
  { name: "reasonForRejection", label: "Rejection Reason", type: "text" },
  { name: "deliveryLineBlock", label: "	Schedule Line Delivery Block", type: "text" },
  { name: "sapOrderLineNumber", label: "Refernce Item Number", type: "text" },
  { name: "deliveryDate", label: "	Schedule Line Delivery Date", type: "date" },
  { name: "schLineNumber", label: "Schedule Line Number", type: "text" },
  { name: "lineStatus", label: "Schedule Line Confirmation Status", type: "text" },
  { name: "confirmedQty", label: "Confirmed Quantity", type: "text" },
  { name: "schedShipDate", label: "Goods Issue Date", type: "date" },
  { name: "materialAvailableDate", label: "Material Availability Date", type: "date" },
  { name: "deliveredQty", label: "Schedule Line Delivery Quantity", type: "text" },
];

export const transactionDetailTabs = [
  {
    title: "order",
    status: "orderStatus",
    apiRoute: "orders",
    fixedHeadCells: orderFixedHeadCells,
    flexibleHeadCells: orderHeadCells,
    formList: orderedFormList,
  },
  {
    title: "delivery",
    status: "deliveryStatus",
    apiRoute: "deliveries",
    fixedHeadCells: deliveryFixedHeadCells,
    flexibleHeadCells: deliveryHeadCells,
    formList: deliveryFormList,
  },
  {
    title: "invoice",
    status: "invoiceStatus",
    apiRoute: "invoices",
    fixedHeadCells: invoiceFixedHeadCells,
    flexibleHeadCells: invoiceHeadCells,
    formList: invoiceFormList,
  },
  {
    title: "workflow",
    status: "none",
    apiRoute: "none",
    fixedHeadCells: [],
    flexibleHeadCells: [],
    formList: [],
  },
];

export const inboundDetailTabs = [
  {
    title: "inboundOrders",
    status: "orderStatus",
    apiRoute: "inboundOrders",
    fixedHeadCells: jjccFixedHeadCells,
    flexibleHeadCells: jjccHeadCells,
    formList: jjccFormList,
  },
  {
    title: "workflow",
    status: "none",
    apiRoute: "none",
    fixedHeadCells: [],
    flexibleHeadCells: [],
    formList: [],
  },
];

export const pharmaTransactionsDetailsTabs = [
  {
    title: "pharmaOrder",
    status: "pharmaOrderStatus",
    apiRoute: "pharmaOrders",
    fixedHeadCells: [],
    flexibleHeadCells: pharmaOrderHeadCells,
    formList: pharmaOrdersFormList,
  },
];

export const transactionDetailsElements: { sap: ITransactionDetailsElements; jjcc: ITransactionDetailsElements } = {
  sap: {
    name: "transactions",
    headerText: "ORDER NO. ",
    headerNumber: "order",
    tabData: [
      { label: "Order Details", status: "orderStatus" },
      { label: "Delivery Details", status: "deliveryStatus" },
      { label: "Invoice Details", status: "invoiceStatus" },
      { label: "Workflow", disabled: false },
    ],
    detailTabs: transactionDetailTabs,
  },
  jjcc: {
    name: "inboundTransactions",
    headerText: "HYBRIS ORDER NO. ",
    headerNumber: "inboundOrders",
    tabData: [
      { label: "JJCC Order Details", status: "orderStatus" },
      { label: "Workflow", disabled: false },
    ],
    detailTabs: inboundDetailTabs,
  },
};

export const pharmaTransactionDetailsElements: { sap: ITransactionDetailsElements; jjcc: ITransactionDetailsElements } =
  {
    sap: {
      name: "pharmaTransactions",
      headerText: "ORDER NO. ",
      headerNumber: "pharmaOrder",
      tabData: [
        { label: "Order Details", status: "orderStatus" },
        { label: "Delivery Details", status: "deliveryStatus" },
        { label: "Invoice Details", status: "invoiceStatus" },
        { label: "Workflow", disabled: false },
      ],
      detailTabs: pharmaTransactionsDetailsTabs,
    },
    jjcc: {
      name: "inboundTransactions",
      headerText: "HYBRIS ORDER NO. ",
      headerNumber: "inboundOrders",
      tabData: [
        { label: "JJCC Order Details", status: "orderStatus" },
        { label: "Workflow", disabled: false },
      ],
      detailTabs: inboundDetailTabs,
    },
  };
