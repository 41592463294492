import { FC } from "react";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import { Line } from "react-chartjs-2";

import { timeChartOption } from "./constants";

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface ITimeChartProps {
  transactionTimeChartData: ChartData<"line">;
}

const TimeChart: FC<ITimeChartProps> = ({ transactionTimeChartData }) => {
  return <Line options={timeChartOption} data={transactionTimeChartData} />;
};

export default TimeChart;
