import React from "react";
import { Box, TableCell, TableRow, TableHead, TableSortLabel, Typography } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { ITableRowFrame, OrderEnum } from "./types";

interface ITHeaderProps {
  tableCellData: ITableRowFrame[];
  onRequestSort: (val: string) => void;
  order: OrderEnum;
  orderBy: string;
}

const THeader: React.FC<ITHeaderProps> = ({ tableCellData, order, orderBy, onRequestSort }) => {
  return (
    <TableHead>
      <TableRow>
        {tableCellData
          .filter(({ isVisible = true }) => isVisible)
          .map(({ name, label, minWidth }) => (
            <TableCell
              key={name}
              sortDirection={orderBy === name ? order : false}
              sx={{
                borderRight: "1px solid #c6c6c6",
                borderTop: "1px solid #c6c6c6",
                backgroundColor: "#f2f9fb",
                fontWeight: "bold",
                minWidth: minWidth,
              }}
            >
              <TableSortLabel
                active={orderBy === name}
                direction={orderBy === name ? order : OrderEnum.ASC}
                onClick={() => onRequestSort(name)}
              >
                <Typography ml={1} whiteSpace={"nowrap"}>
                  {label}
                </Typography>
                {orderBy === name ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === OrderEnum.DEC ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
};

export default THeader;
