import { Container } from "@mui/material";

import Product from "../components/product";

const ProductPage = () => {
  return (
    <Container maxWidth="xl" sx={{ minHeight: "calc(100vh - 270px)" }}>
      <Product />
    </Container>
  );
};

export default ProductPage;
