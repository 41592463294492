/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { options } from "./../constants";
import { AlertTypeEnum, useAlert } from "../../../providers/AlertProvider";
import { useUser } from "../../../providers/UserProvider";
import { postApi } from "../../../service";
import Loader from "../../common/loader/Loader";
import Chart from "../../common/chart/Chart";
import { ChartTypeEnum } from "../../common/chart/constants";
import { purchaseOrderDataToChartData } from "./../helper";
import { countryDetails } from "../../common/constants/countries";

const OrderQuantity = ({ formData, onSearch }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { addAlert } = useAlert();
  const dateFormat = countryDetails[user.countries[0]].dateFormat;
  const [resData, setResData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);

  const dataFetch = async () => {
    setIsLoading(true);
    try {
      if (
        formData.duration === "custom" &&
        (!formData.startDate ||
          !formData.endDate ||
          new Date(formData.startDate) > new Date(formData.endDate))
      ) {
      } else {
        const res = await postApi(`${process.env.REACT_APP_POST}`, {
          api_name: "OrderInsightTrendByQuantity",
          org: process.env.REACT_APP_ORG,
          sector: process.env.REACT_APP_SECTOR,
          countries: user.countries,
          ...formData,
        });

        const { data, status, message } = res.data;
        if (!status) {
          addAlert({ message, type: AlertTypeEnum.ERROR });
        } else {
          setResData(purchaseOrderDataToChartData(data, dateFormat));
        }
      }
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    dataFetch();
  }, [onSearch]);

  return (
    <Box sx={{ flex: 1 }}>
      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={{ height: 380, pt: 2, position: "relative" }}>
          <Typography
            variant="body1"
            align="center"
            sx={{
              transform: "rotate(-90deg)",
              transformOrigin: "0 0",
              position: "absolute",
              top: 200,
              left: 0,
              fontSize: "14px",
              fontWeight: 600,
            }}>
            {t("ORDER_QUANTITY")}
          </Typography>
          <Box sx={{ height: 330, ml: 4 }} flex={1}>
            <Chart
              chartType={ChartTypeEnum.LINE}
              data={resData}
              options={options}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default OrderQuantity;
