import React from "react";
import { AppBar, Box, Button, Container, styled } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import NavItems from "./NavItems";
import UserSettings from "../common/UserSettings";
import { ReactComponent as Logo } from "../../media/icons/logo.svg";
import EnvironmentToggle from "./EnvironmentToggle";

interface INavBarProps {
  isPrivate?: boolean;
}

const CustomButton = styled((props: { children: React.ReactNode }) => (
  <Button {...props} variant="text" endIcon={<KeyboardArrowDown color="primary" />}>
    {props.children}
  </Button>
))(({ theme }) => ({
  fontWeight: "bold",
  lineHeight: "26px",
  fontSize: "14px",
  fontFamily: "'JJCircularMedium','Helvetica', 'Arial', sans-serif",
  color: theme.palette.text.primary,
  textDecoration: "underline",
  borderLeft: `1px solid ${theme.palette.grey.A100}`,
  borderRadius: 0,
  height: "26px",
  "&:hover": {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    backgroundColor: "transparent",
  },
}));

const NavBar: React.FC<INavBarProps> = ({ isPrivate = true }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  // const [{ route }] = matchRoutes(routes, location)

  return (
    <AppBar sx={{ backgroundColor: "#fff", color: "#212121" }}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "76px",
          alignItems: "center",
        }}
        maxWidth="xl"
      >
        <Box sx={{ pr: 9 }}>
          <Logo onClick={() => navigate("/")} />
        </Box>
        {location.pathname === "/" && <CustomButton>{t("MEDTECH")}</CustomButton>}
        {isPrivate && (
          <>
            <NavItems />

            {location.pathname.includes("/catalog/overview/") && <EnvironmentToggle />}

            <UserSettings />
          </>
        )}
      </Container>
    </AppBar>
  );
};

export default NavBar;
