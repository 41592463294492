import React, { useEffect, useState } from "react";
import { Box, Skeleton, Stack, Typography } from "@mui/material";

import BasicHeader from "../common/BasicHeader";
import Dropdown from "../common/inputs/Dropdown";
import ReportChart from "./ReportChart";
import { getApi } from "../../service";
import { dayOptions, countryOptions } from "./constants";
import {
  DayType,
  DayOption,
  CountryType,
  CountryOption,
  ISalesActivityProps,
  OptionDataN,
  CountryOptionDataN,
  ICustomerDetailsData,
} from "./types";

const SalesActivity: React.FC<ISalesActivityProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dayOptionState, setDayOptionState] = useState<OptionDataN>({
    value: DayType.THIRTY,
    label: DayOption.THIRTY,
  });
  const [countryOptionState, setCountryOptionState] = useState<CountryOptionDataN>({
    value: CountryType.AU,
    label: CountryOption.AUSTRALIA,
  });
  const [customerDetails, setCustomerDetails] = useState<ICustomerDetailsData>({
    sales: [],
    type: "",
    activeCustomerData: [],
    countAllSoldToAccountData: [],
    countActiveSoldToAccountData: [],
    customerDetailsData: [],
    leastRevenueSoldToAccountData: [],
    topPerformingSoldToAccountData: [],
    noOfOrdersLeastRevenueData: [],
    inactiveCustomerData: [],
    totalActiveCustomerData: [],
    topThreePerformingCustomerData: [],
    totalSalesData: [],
    topSalesData: [],
    totalOrdersCreatedData: [],
    totalReturnOrdersData: [],
    totalTransactionsData: [],
    totalFailedTransactionsData: [],
    totalSalesPerCustomerPerMonth: [],
    totalProductsSoldData: [],
    topSoldProductData: [],
    topReturnedProductData: [],
  });

  useEffect(() => {
    (async () => {
      const res = await getApi(
        `${process.env.REACT_APP_CHART_URL}?customerReport=true&chartType=cc&filterType=${dayOptionState.value}&countryType=${countryOptionState.value}`
      );
      setCustomerDetails(res.data);
      setIsLoading(false);
    })();
  }, [dayOptionState, countryOptionState]);

  return (
    <>
      {isLoading ? (
        <Skeleton variant="rounded" width={"100%"} height={360} sx={{ mb: 2 }} />
      ) : (
        <>
          <Box
            sx={{
              borderRadius: "8px",
              width: "100%",
              p: "20px",
              boxShadow: "0px 0px 10px 2px #00000033",
              //boxShadow:"0px 0px 2px 2px rgba(0,0,0,.15)",
            }}
          >
            <Typography
              sx={{
                fontSize: "24px",
                display: "block",
                fontWeight: "bold",
                pb: "25px",
              }}
            >
              Sales Activity
            </Typography>
            <Stack direction="row" gap="15px">
              <Box>
                <BasicHeader
                  title="Country"
                  customStyle={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#212121",
                    pb: "5px",
                  }}
                ></BasicHeader>
                <Dropdown
                  optionData={countryOptions}
                  preSelectedValue={countryOptionState}
                  handleValueChange={(val) => setCountryOptionState(val as CountryOptionDataN)}
                />
              </Box>
              <Box>
                <BasicHeader
                  title="Date Range"
                  customStyle={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#212121",
                    pb: "5px",
                  }}
                ></BasicHeader>
                <Dropdown
                  optionData={dayOptions}
                  preSelectedValue={dayOptionState}
                  handleValueChange={(val) => setDayOptionState(val as OptionDataN)}
                />
              </Box>
            </Stack>

            <Stack direction="row" gap="15px">
              <Stack direction="row" gap="15px">
                <Box sx={{ width: "313px" }}>
                  <Typography
                    sx={{
                      fontSize: "0.8rem",
                      display: "block",
                      pt: "25px",
                      textTransform: "uppercase",
                      color: "#888B8D",
                      letterSpacing: "0.25px",
                      fontWeight: "bold",
                    }}
                  >
                    ACTIVE CUSTOMERS
                  </Typography>
                  <Typography sx={{ fontSize: "24px", py: "5px", fontWeight: "bold" }}>
                    {customerDetails.totalActiveCustomerData[0]["Total Active Customer"]}
                  </Typography>
                  <Typography variant="h3">Top 3 customers:</Typography>
                  <Typography sx={{ fontSize: "14px", pb: "5px" }}>
                    {customerDetails.topThreePerformingCustomerData.map((index: any) => (
                      <span key={index.toString()}>{index["Account Id"]},&nbsp;</span>
                    ))}
                  </Typography>
                  &nbsp;
                  <Typography variant="h3">Inactive customers:</Typography>
                  <Typography sx={{ fontSize: "14px" }}>
                    {customerDetails.inactiveCustomerData.map((index: any) => (
                      <span key={index.toString()}>{index["Inactive Account Id"]},&nbsp;</span>
                    ))}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      display: "block",
                      pt: "25px",
                      textTransform: "uppercase",
                      color: "#888B8D",
                      letterSpacing: "0.25px",
                      fontWeight: "bold",
                    }}
                  >
                    TOTAL ORDERS CREATED
                  </Typography>
                  <Typography sx={{ fontSize: "24px", py: "5px", fontWeight: "bold" }}>
                    {<span>{customerDetails.totalOrdersCreatedData[0].totalOrders}</span>}
                  </Typography>
                  <Typography variant="h3">Return orders:</Typography>
                  <Typography sx={{ fontSize: "14px", pb: "5px" }}>
                    {<span>{customerDetails.totalReturnOrdersData[0].totalReturnOrders}</span>}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      display: "block",
                      pt: "25px",
                      textTransform: "uppercase",
                      color: "#888B8D",
                      letterSpacing: "0.25px",
                      fontWeight: "bold",
                    }}
                  >
                    TOTAL PRODUCTS SOLD
                  </Typography>
                  <Typography sx={{ fontSize: "24px", py: "5px", fontWeight: "bold" }}>
                    {<span>{customerDetails.totalProductsSoldData[0].totalProducts}</span>}
                  </Typography>
                  <Typography variant="h3">Top Sold Product ID:</Typography>
                  <Typography sx={{ fontSize: "14px", pb: "5px" }}>
                    {<span>{customerDetails.topSoldProductData[0].topSoldProduct}</span>}
                  </Typography>
                  {customerDetails.topReturnedProductData.length > 0 ? (
                    <>
                      <Typography variant="h3">Top returned Product ID:</Typography>
                      <Typography sx={{ fontSize: "14px", pb: "5px" }}>
                        {<span>customerDetails.topReturnedProductData[0] .topReturnedProduct</span>}
                      </Typography>
                    </>
                  ) : (
                    <span></span>
                  )}
                </Box>
                <Box sx={{ width: "313px" }}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      display: "block",
                      pt: "25px",
                      textTransform: "uppercase",
                      color: "#888B8D",
                      letterSpacing: "0.25px",
                      fontWeight: "bold",
                    }}
                  >
                    TOTAL SALES (AUD)
                  </Typography>
                  <Typography sx={{ fontSize: "24px", py: "5px", fontWeight: "bold" }}>
                    {Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(customerDetails.totalSalesData[0].totalSales)}
                  </Typography>
                  <Typography sx={{ fontSize: "14px", height: "40px" }}>&nbsp; &nbsp;</Typography>
                  <Typography sx={{ fontSize: "14px", height: "40px" }}>&nbsp; &nbsp;</Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      display: "block",
                      pt: "25px",
                      textTransform: "uppercase",
                      color: "#888B8D",
                      letterSpacing: "0.25px",
                      fontWeight: "bold",
                    }}
                  >
                    TOTAL TRANSACTIONS
                  </Typography>
                  <Typography sx={{ fontSize: "24px", py: "5px", fontWeight: "bold" }}>
                    {<span>{customerDetails.totalTransactionsData[0].totalTransactions}</span>}
                  </Typography>
                  <Typography sx={{ fontSize: "12px", py: "5px" }}>
                    {
                      <span>
                        {(
                          (Math.round(
                            (customerDetails.totalFailedTransactionsData[0].totalFailedTransactions * 100) /
                              customerDetails.totalTransactionsData[0].totalTransactions
                          ) *
                            100) /
                          100
                        ).toFixed(2)}
                        % of transactions failed
                      </span>
                    }
                  </Typography>
                </Box>
              </Stack>
              <ReportChart />
            </Stack>
            {/* <Stack>
              <Box
                sx={{
                  width: "642px",
                  border: "1px #0C8CAA solid",
                  px: "15px",
                  py: "5px",
                  mt: "20px",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    textTransform: "capitalize",
                    fontWeight: "bold",
                    color: "#0C8CAA",
                    letterSpacing: "0.25px",
                  }}
                >
                  <TrendingUp sx={{ color: "#0C8CAA", verticalAlign: "middle" }} />
                  INSIGHT
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  AN112732 is predicted to have 30%
                  less orders in the next month
                </Typography>
              </Box>
            </Stack> */}
          </Box>
        </>
      )}
    </>
  );
};

export default SalesActivity;
