import { Dispatch, FC, Fragment, SetStateAction, useEffect, useState } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";

import InterfaceCard from "../interface/InterfaceCard";
import InterfaceFieldsModal from "../interface/InterfaceFieldsModal";
import { useUser } from "../../../providers/UserProvider";
import { postApi } from "../../../service";
import { IFormDetails } from "../../common/types";
import Loader from "../../common/loader/Loader";
import SelectProduct from "./SelectProduct";
import { ISuccessAndFailureCount } from "../types";
import { useEnvironment } from "../../../providers/EnvironmentProvider";
import { integrationCatalogUsageTracking } from "../../../helper";

interface ISatelliteProps {
  product: any;
  setTabValue: Dispatch<SetStateAction<number>>;
  formDetails: IFormDetails;
  tabValue:number;
}

const Satellite: FC<ISatelliteProps> = ({ product, setTabValue,tabValue, formDetails }) => {
  const { user } = useUser();
  const { environmentState } = useEnvironment();

  const [isLoading, setIsLoading] = useState(true);
  const [selectedInterface, setSelectedInterface] = useState({});
  const [productInterfaces, setProductInterfaces] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [interfaceProduct, setInterfaceProduct] = useState<string>("all");
  const [successAndFailureCount, setSuccessAndFailureCount] = useState<ISuccessAndFailureCount[]>([]);

  const fetchProductInterfaces = async (filter?: { filter: string }) => {
    setIsLoading(true);

    const res = await postApi(`${process.env.REACT_APP_POST}`, {
      api_name: "GetInterfaceListForProduct",
      org: "hmd-jjcc-nonprod", // process.env.REACT_APP_ORG,
      sector: process.env.REACT_APP_SECTOR,
      countries: user.countries,
      product: product.value,
      ...(filter ? filter : {}),
    });

    try {
      return res.data.data;
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      const response = await postApi(`${process.env.REACT_APP_POST}`, {
        org: "hmd-jjcc-nonprod-qa",
        sector: "medical",
        env: environmentState ? environmentState : process.env.PERFORMANCE_INDEX || null,
        api_name: "PerformanceInsightForAllInterfaceSuccessAndFailureCount",
        duration: "1 Month",
      });

      setSuccessAndFailureCount(response.data.data);

      const res = await fetchProductInterfaces();

      if (res) {
        setProductInterfaces(res);
      }
      setIsLoading(false);
    })();
  }, []);

  const handleClick = (interfaceDetails) => {
    integrationCatalogUsageTracking(user,"IC_Satellite_card_clicked");// for usage tracking
    setSelectedInterface(interfaceDetails);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedInterface({});
    setOpen(false);
  };

  return (
    <>
      <Stack sx={{ float: "right" }}>
        <SelectProduct
          setInterfaceProduct={setInterfaceProduct}
          productOptions={[
            { label: "All", value: "all" },
            // { label: "Mars", value: "mars" },
            // { label: "Panda", value: "panda" },
          ]}
        />
      </Stack>

      <Box p={3}>
        {isLoading ? (
          <Loader />
        ) : (
          <Grid container justifyContent="left" spacing={4}>
            {productInterfaces.length ? (
              productInterfaces.map((item, i) => {
                if (interfaceProduct !== "all" && interfaceProduct !== item.interface_product) {
                  return <Fragment key={i} />;
                }

                const ele = successAndFailureCount?.find((e) => e.inteface === item.interface);

                return (
                  <Grid key={i} item>
                    <InterfaceCard
                      name={item.interface}
                      description={item.interface_description}
                      lastUpdated={item.rec_updated_timestamp}
                      integration_flow={item.integration_flow}
                      reusability={item.reusability}
                      middleware={item.middleware}
                      handleClick={() => handleClick(item)}
                      setTabValue={setTabValue}
                      formDetails={formDetails}
                      successCount={ele?.successCount ?? 0}
                      failureCount={ele?.failureCount ?? 0}
                      successRate={ele?.successRate ?? 0}
                      failureRate={ele?.failureRate ?? 0}
                      tabValue={tabValue}
                    />
                  </Grid>
                );
              })
            ) : (
              <Typography align="center" justifyContent="center" justifyItems="center">
                Data not available
              </Typography>
            )}
          </Grid>
        )}
      </Box>

      <InterfaceFieldsModal open={open} interfaceDetails={selectedInterface} handleClose={handleClose} />
    </>
  );
};

export default Satellite;
