import React, { createContext, useContext, useState } from "react";
import { IChildrenProps } from "../components/common/types";

export interface IFormContext {
  isEditable: boolean;
  setIsEditable: (val: boolean) => void;
}

export const FormContext = createContext<IFormContext>({
  isEditable: false,
  setIsEditable: () => {},
});

export const useForm = () => {
  const context = useContext(FormContext);
  if (context === undefined) {
    throw new Error("useForm must be used within a FormProvider");
  }
  return context;
};

export const FormProvider: React.FC<IChildrenProps> = ({ children }) => {
  const [isEditable, setIsEditable] = useState<boolean>(false);

  return <FormContext.Provider value={{ isEditable, setIsEditable }}>{children}</FormContext.Provider>;
};
