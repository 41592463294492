export const selfServiceTabs = ["order", "delivery", "invoice"];
export const sapTransactions = ["sapOrder", "delivery", "invoice", "insights"];

export const config = [
  {
    tab: "order",
    categories: [
      { key: "orderDetails", name: "Order details" },
      { key: "soldShipPo", name: "Sold to, Ship to, P.O." },
      { key: "operation", name: "operation case" },
      { key: "products", name: "Products" },
    ],
  },
  {
    tab: "delivery",
    categories: [
      { key: "deliveryDetails", name: "Delivery details" },
      { key: "transportDetails", name: "Transport details" },
      { key: "products", name: "Products" },
    ],
  },
  {
    tab: "invoice",
    categories: [
      { key: "invoiceDetails", name: "Invoice details" },
      { key: "soldBillShip", name: "Sold to, Bill to, Ship to, P.O." },
      { key: "products", name: "Products" },
    ],
  },
  // {
  //   tab: "insights",
  //   categories: [
  //     { key: "homeView", name: "Home View" },
  //     { key: "insightView", name: "Insights View" },
  //   ],
  // },
];
