/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Container, Stack } from "@mui/material";

import { handleKeysFromParams } from "../../helper";
import NavigateBack from "../navbar/NavigateBack";
import VersionSelector from "../transactionDetails/VersionSelector";
import BasicTabs from "../common/BasicTabs";
import WorkFlow from "../selfServiceDetails/workflow/WorkFlow";
import { postApi } from "../../service";
import {
  deliveryFixedHeadCells,
  deliveryFormList,
  deliveryHeadCells,
  invoiceFixedHeadCells,
  invoiceFormList,
  invoiceHeadCells,
  orderFixedHeadCells,
  orderHeadCells,
  orderedFormList,
} from "../selfServiceDetails/constants";
import { getIcon, handleNodeClick } from "../selfServiceDetails/helper";
import { StyledTabs } from "../common/types";
import { ITableRowFrame, StatusTypeEnum } from "../common/table/types";
import { IServiceList } from "../transactions/types";
import TransactionTab from "./TransactionTab";
import { useUser } from "../../providers/UserProvider";

export interface ITabData extends StyledTabs {
  title: string;
  status: StatusTypeEnum;
  auditRequestData: { [key in string]: string };
  productRequestData: { [key in string]: string };
  fixedHeadCells: ITableRowFrame[];
  flexibleHeadCells: ITableRowFrame[];
  formList: IServiceList[][];
}

const TransactionDetails = () => {
  const { search } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { sapOrderNumber, deliveryNumber, invoiceNumber } = handleKeysFromParams(search);
  const { user } = useUser();

  const [tabIdx, setTabIdx] = useState<number>(0);
  const [versions, setVersions] = useState<any[]>([]);
  const [currentVersion, setCurrentVersion] = useState<number | null>(null);
  const [tabData, setTabData] = useState<ITabData[]>([]);
  const [status, setStatus] = useState<string>("START");
  const [error, setError] = useState<string>("");
  const [fullMessage, setFullMessage] = useState<string>("");

  useEffect(() => {
    setTabIdx(id === "delivery" ? 1 : id === "invoice" ? 2 : 0);

    (async () => {
      const res = await postApi(`${process.env.REACT_APP_POST}`, {
        api_name: "SingleRecord",
        index_name: `${process.env.REACT_APP_ORG}-${process.env.REACT_APP_SECTOR}-order-details`,
        countries: user.countries || [],
        key: "sapOrderNumber",
        value: sapOrderNumber,
        org: process.env.REACT_APP_ORG,
        sector: process.env.REACT_APP_SECTOR,
      });

      let deliveryStatus =
        res.data.data.result[0]._source?.deliveryNumber &&
        res.data.data.result[0]._source.deliveryNumber.find((e) => e.number === deliveryNumber).status;
      let invoiceStatus =
        res.data.data.result[0]._source?.invoiceNumber &&
        res.data.data.result[0]._source.invoiceNumber.find((e) => e.number === invoiceNumber).status;
      setStatus(res.data.data.result[0]._source.orderStatus);
      setError(res.data.data.result[0]._source.orderStatus);
      setFullMessage(res.data.data.result[0]._source.orderStatusMessage);
      if (res.status < 300 && sapOrderNumber) {
        setTabData([
          res.data.data.result[0]._source.sapOrderNumber && {
            label: "Order Details",
            icon: getIcon(res.data.data.result[0]._source.orderStatus),
            status: res.data.data.result[0]._source.orderStatus,
            title: "order",
            auditRequestData: {
              api_name: "DetailList",
              index_name: `${process.env.REACT_APP_ORG}-${process.env.REACT_APP_SECTOR}-order-detail-audit`,
              key: "sapOrderNumber",
              countries: user.countries || [],
              value: sapOrderNumber,
              size: 5,
              sort_by_datetime: "desc",
            },
            productRequestData: {
              api_name: "SingleRecord",
              index_name: `${process.env.REACT_APP_ORG}-${process.env.REACT_APP_SECTOR}-product-detail`,
              countries: user.countries || [],
              key: "sapOrderNumber",
              value: res.data.data.result[0]._source.sapOrderNumber,
            },
            fixedHeadCells: orderFixedHeadCells,
            flexibleHeadCells: orderHeadCells,
            formList: orderedFormList,
          },
          {
            label: `Delivery Details`,
            icon:
              deliveryStatus &&
              getIcon(id === "delivery" ? deliveryStatus : res.data.data.result[0]._source?.deliveryNumber[0].status),
            status: deliveryStatus
              ? id === "delivery"
                ? deliveryStatus
                : res.data.data.result[0]._source?.deliveryNumber[0].status
              : StatusTypeEnum.ERROR,
            title: "delivery",
            auditRequestData: {
              api_name: "DetailList",
              index_name: `${process.env.REACT_APP_ORG}-${process.env.REACT_APP_SECTOR}-delivery-detail-audit`,
              countries: user.countries || [],
              key: "deliveryNum",
              value: deliveryNumber,
              size: 5,
            },
            productRequestData: {
              api_name: "SingleRecord",
              index_name: `${process.env.REACT_APP_ORG}-${process.env.REACT_APP_SECTOR}-delivery-details`,
              countries: user.countries || [],
              key: "deliveryNum",
              value: deliveryNumber,
            },
            fixedHeadCells: deliveryFixedHeadCells,
            flexibleHeadCells: deliveryHeadCells,
            formList: deliveryFormList,
            disabled: !deliveryStatus,
          },
          {
            label: `Invoice Details`,
            icon:
              invoiceStatus &&
              getIcon(id === "invoice" ? invoiceStatus : res.data.data.result[0]._source?.invoiceNumber[0].status),
            status: invoiceStatus
              ? id === "invoice"
                ? invoiceStatus
                : res.data.data.result[0]._source?.invoiceNumber[0].status
              : StatusTypeEnum.ERROR,
            title: "invoice",
            auditRequestData: {
              api_name: "DetailList",
              index_name: `${process.env.REACT_APP_ORG}-${process.env.REACT_APP_SECTOR}-invoice-detail-audit`,
              countries: user.countries || [],
              key: "invoiceDocumentNumber",
              value: invoiceNumber,
              size: 5,
            },
            productRequestData: {
              api_name: "SingleRecord",
              index_name: `${process.env.REACT_APP_ORG}-${process.env.REACT_APP_SECTOR}-invoice-details`,
              countries: user.countries || [],
              key: "invoiceDocumentNumber",
              value: invoiceNumber,
            },
            fixedHeadCells: invoiceFixedHeadCells,
            flexibleHeadCells: invoiceHeadCells,
            formList: invoiceFormList,
            disabled: !invoiceStatus,
          },
          { label: "Workflow", disabled: false, title: "workflow" },
        ]);
      }
    })();
  }, [search]);

  return (
    <Container maxWidth="xl">
      <Stack direction="row" sx={{ mt: 3 }}>
        <NavigateBack headerText={`TRANSACTION NO. ${sapOrderNumber}`} />
        <VersionSelector
          status={status}
          versions={versions}
          currentVersion={currentVersion}
          setCurrentVersion={setCurrentVersion}
        />
      </Stack>

      <BasicTabs
        data={tabData}
        components={tabData.map(({ title, ...rest }) => {
          if (title === "workflow") {
            return (
              <WorkFlow
                key={title}
                {...rest}
                order={{
                  api_name: "Workflow",
                  countries: user.countries || [],
                  index_name: `${process.env.REACT_APP_ORG}-${process.env.REACT_APP_SECTOR}-order-details`,
                  key: "sapOrderNumber",
                  value: `inbound/${sapOrderNumber}`,
                  org: process.env.REACT_APP_ORG,
                  sector: process.env.REACT_APP_SECTOR,
                }}
                onNodeClick={(data: any) =>
                  Number(data.number) && setTabIdx(handleNodeClick({ ...data, navigate, search }))
                }
              />
            );
          }

          return (
            <TransactionTab
              key={title}
              {...rest}
              title={title}
              versions={versions}
              setVersions={setVersions}
              currentVersion={currentVersion}
              setCurrentVersion={setCurrentVersion}
              error={error}
              fullMessage={fullMessage}
            />
          );
        })}
        defaultTab={0}
        currentTab={tabIdx}
        setCurrentTab={(idx) => setTabIdx(idx)}
      />
    </Container>
  );
};

export default TransactionDetails;
