export const routeDefinitions = [
  // { path: "/", label: "HOME" },
  { path: "/", label: "Insights" },
  { path: "/login", label: "Login" },
  // { path: "/insights", label: "Insights" },
  { path: "/self-service/sap", label: "SAP TRANSACTIONS" },
  { path: "/self-service/jjcc", label: "JJCC TRANSACTIONS" },
  { path: "/self-service/configuration", label: "JJCC TRANSACTIONS" },
  { path: "/self-service/sap/details", label: "ORDER DETAILS" },
  { path: "/self-service/jjcc/details", label: "ORDER DETAILS" },
];
