interface DataType {
  category: string;
  disabled: boolean;
  id: boolean;
  key: string;
  label: string;
  type: string;
}

export const configurationReview = (data: DataType[]) => {
  let obj: any = {};

  data.forEach((e) => {
    const { type, category } = e;

    if (obj[type]) {
      if (obj[type][category]) {
        obj = { ...obj, [type]: { ...obj[type], [category]: [...obj[type][category], e] } };
      } else {
        obj = { ...obj, [type]: { ...obj[type], [category]: [e] } };
      }
    } else {
      obj = { ...obj, [type]: { [category]: [e] } };
    }
  });

  return obj;
};
