import { Stack } from "@mui/material";

import DropNText from "./DropNText";
import { optionData } from "./constants";

interface IInputFiltersProps {
  formDetails: any;
}

const InputFilters: React.FC<IInputFiltersProps> = ({ formDetails }) => {
  const { setValue, getValues } = formDetails;

  const handleTextChange = (val: string) => {
    if (!getValues("searchKeys")) {
      setValue(
        "searchKeys",
        optionData.map(({ value }) => value)
      );
    }
    setValue("searchText", val);
  };

  return (
    <Stack direction="row" flexWrap="wrap" p="20px" columnGap={2} rowGap={3} component="form">
      <DropNText
        label="Search Account"
        placeholder="Search by Account ID or Account Name"
        handleOptionChange={(val) =>
          setValue(
            "searchKeys",
            val.map(({ value }) => value)
          )
        }
        optionData={optionData}
        handleTextChange={handleTextChange}
        style={{ width: "419px" }}
      />
    </Stack>
  );
};

export default InputFilters;
