import { useState, useEffect } from "react";

import ProductList from "./productList";
import CatalogHeader from "./CatalogHeader";
import { useUser } from "../../providers/UserProvider";
import { postApi } from "../../service";
import Loader from "../common/loader/Loader";

const Catalog = () => {
  const { user } = useUser();

  const [productList, setProductList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getProductList = async () => {
    setIsLoading(true);

    const res = await postApi(`${process.env.REACT_APP_POST}`, {
      api_name: "GetAllProductApiCatalogDetails",
      org: "hmd-jjcc-nonprod", //process.env.REACT_APP_ORG,
      sector: process.env.REACT_APP_SECTOR,
      countries: user.countries,
    });

    try {
      setProductList(res.data.data);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getProductList();
  }, []);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <CatalogHeader products={productList} />
          <ProductList products={productList} />
        </>
      )}
    </>
  );
};

export default Catalog;
