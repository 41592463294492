import { Divider, Stack, Typography, Box } from "@mui/material";

import { amountTotal } from "../constants";

const AmountDetail = () => {
  return (
    <>
      {/* <Divider sx={{ borderBottomWidth: "4px" }} /> */}
      {/* <Box sx={{ maxWidth: "25%", marginLeft: "auto", padding: "20px" }}>
        {amountTotal.map(({ label, value }) => {
          return (
            <Stack
              key={label}
              direction="row"
              sx={{
                justifyContent: "space-between",
                paddingBottom: "15px",
              }}
            >
              <Typography variant="subtitle1">{label}</Typography>
              <Typography variant="subtitle2">{value}</Typography>
            </Stack>
          );
        })}
      </Box> */}
    </>
  );
};

export default AmountDetail;
