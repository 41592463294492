import { AlertTypeEnum } from "../../providers/AlertProvider";

export interface IInsightAlertProps {
  alertType: AlertTypeEnum;
  message: string;
  value?: string;
  category?: string;
}

export interface IInsightAlertSetupProps {
  insights_name: string;
  description: string;
  valueQuery?: any;
  category?: string;
}



export enum DayType {
  DAY = "day",
  TODAY = "today",
  MONTH = "month",
  WEEK = "week",
  SEVEN = "7",
  THIRTY = "30",
}

export enum DayOption {
  DAILY = "Daily",
  WEEKLY = "Weekly",
  MONTHLY = "Monthly",
  TODAY = "Today",
  SEVEN = "Last 7 days",
  THIRTY = "Last 30 days",
}

export interface OptionDataN {
  value?: DayType;
  label?: DayOption;
}
