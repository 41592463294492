import React from "react";
import { Stack, Typography } from "@mui/material";

import { alertTypeIcon } from "./constants";
import { IInsightAlertProps } from "./types";

const InsightAlert: React.FC<IInsightAlertProps> = ({ alertType, message, value, category }) => {
  const DynamicIcon = alertTypeIcon[alertType];
  var insight = displayInsight(message, value);

  function displayInsight(description: string, value: any): string {
    if (value) {
      for (const key in value) {
        description = description.toString().replace(`[${key}]`, value[key]);
      }
    }
    return description;
  }

  return (
    <>
      {category === "homeView" ? (
        <Stack
          direction="row"
          sx={{
            border: "1px solid #C6C6C6",
            borderRadius: "4px",
            px: "12px",
            py: "22px",
            my: 2,
            alignItems: "center",
          }}
        >
          <DynamicIcon color={alertType} sx={{ mr: "12px" }} />
          <Typography sx={{ flex: 1 }}>{category === "homeView" && insight}</Typography>
        </Stack>
      ) : (
        <></>
      )}
    </>
  );
};

export default InsightAlert;
