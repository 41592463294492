/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import { OpenInFull } from "@mui/icons-material";

import BasicHeader from "../common/BasicHeader";
import BasicTabs from "../common/BasicTabs";
import DelimitBox from "../common/DelimitBox";
import Chart from "../common/chart/Chart";
import Dropdown from "../common/inputs/Dropdown";
import MaximizeModal from "../common/modal/MaximizeModal";
import OrderAmount from "./OrderAmount";
import { dayOptions } from "./constants";
import { formatDocument } from "./helper";
import { DayType, DayOption, IOrdersOverviewProps, IComponentData, OptionDataN } from "./types";
import { postApi } from "../../service";
import store from "../common/GlobalStore";
import { useUser } from "../../providers/UserProvider";

const OrdersOverview: React.FC<IOrdersOverviewProps> = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  const [currentTabNum] = store.useState<number>("tabIndex");
  const [openFull, setOpenFull] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dayOptionState, setDayOptionState] = useState<OptionDataN>({ value: DayType.WEEK, label: DayOption.WEEKLY });
  const [tabComponentData, setTabComponentData] = useState<IComponentData[]>([]);
  const [originalData, setOriginalData] = useState<any>({ fields: [] });

  useEffect(() => {
    (async () => {
      let resData = await postApi(`${process.env.REACT_APP_POST}`, {
        api_name: "Chart",
        chartType: "oo",
        filterType: dayOptionState.value,
        countries: user.countries || [],
        org: process.env.REACT_APP_ORG,
        sector: process.env.REACT_APP_SECTOR,
      });
      setOriginalData(resData.data.data);
      setTabComponentData(formatDocument(resData.data.data, dayOptionState.value, navigate) as IComponentData[]);
      setIsLoading(false);
    })();
  }, [dayOptionState]);

  return (
    <DelimitBox customStyle={{ flex: 1, height: "70vh" }}>
      <MaximizeModal open={openFull} setOpen={setOpenFull}>
        <BasicHeader title="Orders Overview">
          <Dropdown
            optionData={dayOptions}
            preSelectedValue={dayOptionState}
            handleValueChange={(val) => setDayOptionState(val as OptionDataN)}
          />
          <OpenInFull
            sx={{ height: 34, ml: 2, cursor: "pointer" }}
            color="primary"
            onClick={() => setOpenFull(!openFull)}
          />
        </BasicHeader>

        <BasicTabs
          data={Object.keys(originalData?.fields).map((e) => ({ label: e }))}
          components={
            isLoading
              ? [...Array(2).fill(<div>...Loading</div>)]
              : tabComponentData.map(({ orderAmounts, chart: { chartType, data, options } }) => {
                  if (orderAmounts.length < 2) {
                    if (!orderAmounts[0].amount.includes("$")) {
                      orderAmounts[0].amount = "$" + orderAmounts[0].amount;
                    }
                  }
                  data?.datasets?.forEach((element: any) => {
                    if (!element?.label.includes(" ")) {
                      element.label =
                        element?.label?.charAt(0).toUpperCase() +
                        element?.label
                          ?.substr(1)
                          .replace(/([A-Z])/g, " $1")
                          .trim();
                    }
                  });
                  return (
                    <Stack direction="row" key={chartType}>
                      <Stack style={{ minWidth: 250 }}>
                        {orderAmounts.map((e, i) => (
                          <OrderAmount {...e} key={i} />
                        ))}
                      </Stack>
                      <div
                        style={{ flex: 1, height: openFull ? "65vh" : "40vh", minHeight: "42vh", cursor: "pointer" }}
                      >
                        <Chart chartType={chartType} data={data} options={options} />
                      </div>
                    </Stack>
                  );
                })
          }
          currentTab={currentTabNum}
        />
      </MaximizeModal>
    </DelimitBox>
  );
};

export default OrdersOverview;
