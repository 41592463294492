import { Dispatch, FC, Fragment, SetStateAction, useEffect, useState } from "react";
import { Box, Grid, Stack, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import { useDebouncedCallback } from "use-debounce";

import InterfaceFilter from "./InterfaceFilter";
import InterfaceCard from "./InterfaceCard";
import InterfaceFieldsModal from "./InterfaceFieldsModal";
import { useUser } from "../../../providers/UserProvider";
import { postApi } from "../../../service";
import Loader from "../../common/loader/Loader";
import { IFormDetails } from "../../common/types";
import webMethodLogo from "../../../media/images/webmethods_logo.png";
import mulesoftLogo from "../../../media/images/mulesoft_logo.png";
import { ISuccessAndFailureCount } from "../types";
import { useEnvironment } from "../../../providers/EnvironmentProvider";
import { integrationCatalogUsageTracking } from "../../../helper";

const logoArr = [
  { name: "webmethod", src: webMethodLogo },
  { name: "mulesoft", src: mulesoftLogo },
];

interface IInterfaceProps {
  product: any;
  setTabValue: Dispatch<SetStateAction<number>>;
  formDetails: IFormDetails;
  tabValue:number;
}

const Interface: FC<IInterfaceProps> = ({ product, setTabValue,tabValue, formDetails }) => {
  const theme = useTheme();
  const { user } = useUser();
  const { environmentState } = useEnvironment();

  const [isLoading, setIsLoading] = useState(true);
  const [selectedInterface, setSelectedInterface] = useState({});
  const [productInterfaces, setProductInterfaces] = useState<any[]>([]);
  const [masterProductInterfaces, setMasterProductInterfaces] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [textSearch, setTextSearch] = useState("");
  const [interfaceMiddleware, setInterfaceMiddleware] = useState<string[]>(["webmethod", "mulesoft"]);
  const [successAndFailureCount, setSuccessAndFailureCount] = useState<ISuccessAndFailureCount[]>([]);

  const handleFormat = (event: React.MouseEvent<HTMLElement>, newFormats: string[]) => {
    setInterfaceMiddleware(newFormats);
  };

  const fetchProductInterfaces = async (filter?: { filter: string }) => {
    setIsLoading(true);

    const res = await postApi(`${process.env.REACT_APP_POST}`, {
      api_name: "GetInterfaceListForProduct",
      org: "hmd-jjcc-nonprod", // process.env.REACT_APP_ORG,
      sector: process.env.REACT_APP_SECTOR,
      countries: user.countries,
      product: product.value,
      ...(filter ? filter : {}),
    });

    try {
      return res.data.data;
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      const response = await postApi(`${process.env.REACT_APP_POST}`, {
        org: "hmd-jjcc-nonprod-qa",
        sector: "medical",
        env: environmentState ? environmentState : process.env.PERFORMANCE_INDEX || null,
        api_name: "PerformanceInsightForAllInterfaceSuccessAndFailureCount",
        duration: "1 Month",
      });

      setSuccessAndFailureCount(response.data.data);

      const res = await fetchProductInterfaces();

      if (res) {
        setProductInterfaces(res);
        setMasterProductInterfaces(res);
      }
      setIsLoading(false);
    })();
  }, []);

  const debounce = useDebouncedCallback(async (searchStr) => await toggleSearch(searchStr), 500, { maxWait: 2000 });

  useEffect(() => {
    debounce(textSearch);
  }, [textSearch]);

  const toggleSearch = async (searchStr: string) => {
    if (searchStr !== "") {
      const res = await fetchProductInterfaces({ filter: searchStr });

      if (Array.isArray(res)) {
        setProductInterfaces(res);
      }
    } else {
      setProductInterfaces(masterProductInterfaces);
    }
    setIsLoading(false);
  };

  const handleClick = (interfaceDetails) => {
    integrationCatalogUsageTracking(user,"IC_Interface_card_clicked");// for usage tracking
    setSelectedInterface(interfaceDetails);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedInterface({});
    setOpen(false);
  };

  return (
    <>
      {/* <Typography variant="body1" sx={{ my: 4 }}>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
        standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make
        a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,
        remaining essentially unchanged. It was popularised in the 1960s with the release of Let raset sheets containing
        Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions
        of Lorem Ipsum.
      </Typography> */}

      <Stack direction="row" columnGap={2} rowGap={3} justifyContent={"space-between"}>
        <ToggleButtonGroup value={interfaceMiddleware} onChange={handleFormat} aria-label="text formatting">
          {logoArr.map(({ name, src }) => {
            return (
              <ToggleButton
                value={name}
                aria-label={name}
                key={name}
                sx={{
                  "&.Mui-selected": {
                    bgcolor: `${theme.palette.primary.main}55`,
                  },
                }}
              >
                <img height={24} srcSet={src} src={src} alt={name} loading="lazy" />
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>

        <InterfaceFilter textSearch={textSearch} setTextSearch={setTextSearch} />
      </Stack>
      <Box p={3}>
        {isLoading ? (
          <Loader />
        ) : (
          <Grid container justifyContent="left" spacing={4}>
            {productInterfaces.length ? (
              productInterfaces.map((item, i) => {
                if (!interfaceMiddleware.some((name) => name === item.middleware.toLowerCase())) {
                  return <Fragment key={i} />;
                }

                const ele = successAndFailureCount && successAndFailureCount.find((e) => e.inteface === item.interface);

                return (
                  <Grid key={i} item>
                    <InterfaceCard
                      name={item.interface}
                      description={item.interface_description}
                      lastUpdated={item.rec_updated_timestamp}
                      integration_flow={item.integration_flow}
                      reusability={item.reusability}
                      middleware={item.middleware}
                      handleClick={() => handleClick(item)}
                      setTabValue={setTabValue}
                      formDetails={formDetails}
                      successCount={ele?.successCount ?? 0}
                      failureCount={ele?.failureCount ?? 0}
                      successRate={ele?.successRate ?? 0}
                      failureRate={ele?.failureRate ?? 0}
                      tabValue = {tabValue} // for usage tracking.....
                    />
                  </Grid>
                );
              })
            ) : (
              <Typography align="center" justifyContent="center" justifyItems="center">
                Data not available
              </Typography>
            )}
          </Grid>
        )}
      </Box>

      <InterfaceFieldsModal open={open} interfaceDetails={selectedInterface} handleClose={handleClose} />
    </>
  );
};

export default Interface;
