import dayjs from "dayjs";
import { countryDetails } from "../constants/countries";
import { ITableData, OrderEnum } from "./types";

export const transformCellText = (type: string, row: ITableData, name: keyof ITableData) => {
  switch (type) {
    case "country":
      return countryDetails?.[row?.[name] as keyof typeof countryDetails]?.name ?? row?.[name];

    case "date":
      return dayjs(row?.[name]).isValid() && dayjs(row?.[name]).format("YYYY-MM-DD");

    default:
      return row?.[name];
  }
};

// function to sort data
export const handleSort = (data: ITableData[], isSorted: OrderEnum, sortName: string) => {
  if (isSorted === OrderEnum.ASC) {
    return data.sort((a, b) => {
      if (!a[sortName]) {
        return 1;
      }
      if (!b[sortName]) {
        return -1;
      }
      if (a[sortName] < b[sortName]) {
        return -1;
      }
      if (a[sortName] > b[sortName]) {
        return 1;
      }
      return 0;
    });
  } else {
    return data.sort((a, b) => {
      if (b[sortName] < a[sortName]) {
        return -1;
      }
      if (b[sortName] > a[sortName]) {
        return 1;
      }
      return 0;
    });
  }
};
