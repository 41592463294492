import { useState } from "react";
import { Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

import { IFormDetails } from "../common/types";
import DateSelect from "../common/inputs/DateSelect";
import { btnPrimary, btnSec } from "../insightsv2/constants";
import { dateValues } from "../common/constants/inputs";
import CustomerMultiSelect from "../insightsv2/CustomerMultiSelect";
import { useUser } from "../../providers/UserProvider";
import { usageTrackingMessage } from "../../helper";
import { IWatchForm } from "../insightsv2/productInsights";

interface IInsightFilterProps {
  formDetails: IFormDetails;
  toggleSearch: () => void;
  formData: IWatchForm;
}

const MarketInsightFilter: React.FC<IInsightFilterProps> = ({ toggleSearch, formDetails, formData }) => {
  const { t } = useTranslation();
  const { setValue, reset } = formDetails;
  const { user } = useUser();

  const [isClear, setIsClear] = useState(false);

  const handleClearInputs = () => {
    setIsClear(!isClear);
    toggleSearch();
    reset();
  };

  // message sent to hmd-jjcc-prod-banner-usage for button tracking
  const filedsMessage = {
    "1 Month": "Market_insights_One_month_button_click",
    "3 Month": "Market_insights_Three_month_button_click",
    "6 Month": "Market_insights_Six_month_button_click",
    custom: "Market_insights_Custom_duration_button_click",
  };

  return (
    <Stack direction="row" flexWrap="wrap" pb="20px" columnGap={2} rowGap={3} component="form">
      <CustomerMultiSelect setValue={setValue} isClear={isClear} />
      <DateSelect dateOptions={dateValues(t)} formDetails={formDetails} isClear={isClear} duration={formData?.duration || ""} />

      <Button
        variant="contained"
        sx={btnPrimary}
        onClick={() => {
          toggleSearch();
          // message sent to hmd-jjcc-prod-banner-usage for button tracking
          usageTrackingMessage(user, filedsMessage[formData?.duration || ""]);
        }}
        children={t("SEARCH")}
      />
      <Button sx={btnSec} onClick={handleClearInputs} children={t("RESET")} />
    </Stack>
  );
};

export default MarketInsightFilter;
