import { ChartOptions } from "chart.js";

import { chartColors } from "../common/chart/constants";

export const catalogTabs = [
  { label: "overview" },
  { label: "interface" },
  { label: "satellite" },
  { label: "performance" },
  // { label: "interface failure" },
];

export const options: ChartOptions<"doughnut"> = {
  plugins: {
    legend: {
      position: "right",
      align: "center",
      labels: { padding: 20, usePointStyle: true, useBorderRadius: true, borderRadius: 50 },
    },
  },
  responsive: true,
  elements: { arc: { borderWidth: 0 } },
};

export const productChartData = (data: { noOfInterfaces: number; name: string }[]) => {
  return {
    maintainAspectRatio: false,
    responsive: false,
    labels: data.map(({ name }) => name),
    datasets: [
      {
        data: data.map(({ noOfInterfaces }) => noOfInterfaces),
        backgroundColor: chartColors,
        hoverBackgroundColor: chartColors,
      },
    ],
  };
};

export const platforms = [
  {
    name: "BTB",
    info: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
    totalInterface: 46,
  },
  {
    name: "BTB",
    info: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
    totalInterface: 46,
  },
  {
    name: "BTB",
    info: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
    totalInterface: 46,
  },
];

function createData(name: string, value: string) {
  return { name, value };
}

export const modalRows = [
  createData("Nod soos", "mdos sdosid"),
  createData("Nod soos", "mdos sdosid"),
  createData("Nod soos", "mdos sdosid"),
  createData("Nod soos", "mdos sdosid"),
  createData("Nod soos", "mdos sdosid"),
  createData("Nod soos", "mdos sdosid"),
  createData("Nod soos", "mdos sdosid"),
  createData("Nod soos", "mdos sdosid"),
];

export const modalRows2 = [
  createData("STATUS", "VERSION"),
  createData("ACTIVE", "1.2.6"),
  createData("STATUS", "VERSION"),
  createData("Nod soos", "mdos sdosid"),
];
