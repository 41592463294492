import BasicHeader from "../common/BasicHeader";
import DelimitBox from "../common/DelimitBox";
import InsightAlert from "./InsightAlert";
import { IInsightAlertSetupProps } from "./types";
import { useEffect, useState } from "react";
import { postApi } from "../../service";
import { IconButton, Stack, Typography } from "@mui/material";
import { AlertTypeEnum } from "../../providers/AlertProvider";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useUser } from "../../providers/UserProvider";

const Insights = () => {
  const [insights, setInsights] = useState<IInsightAlertSetupProps[]>([]);
  const { user } = useUser();

  useEffect(() => {
    (async () => {
      await getInsights();
    })();
  }, []);
  const getInsights = async () => {
    const requestBody = {
      org: process.env.REACT_APP_ORG,
      sector: process.env.REACT_APP_SECTOR,
      userId: user.email,
      api_name: "GetUserInsights",
      countries: user.countries || [],
    };
    const insightData = await postApi(`${process.env.REACT_APP_POST}`, requestBody);
    const insightsArr = insightData.data.data;
    insightsArr.forEach((element) => {
      if (element.value) {
        let desc = element.description;
        Object.keys(element.value).forEach((key) => {
          desc = desc.replace(`[${key}]`, element.value[key]);
        });
        element.description = desc;
      }
    });
    setInsights(insightsArr.filter((item) => item.value));
  };
  const handleClick = () => {
    (async () => {
      const requestBody = {
        org: process.env.REACT_APP_ORG,
        sector: process.env.REACT_APP_SECTOR,
        userId: user.email,
        api_name: "TriggerUserUpdateInsights",
        soldTo: user.soldTo,
        countries: user.countries || [],
      };
      await postApi(`${process.env.REACT_APP_POST}`, requestBody);
      setTimeout(function () {
        getInsights();
      }, 3000);
      const requestBodyCharts = {
        org: process.env.REACT_APP_ORG,
        sector: process.env.REACT_APP_SECTOR,
        userId: user.email,
        api_name: "TriggerUserUpdateInsightCharts",
        soldTo: user.soldTo,
        countries: user.countries || [],
      };
      await postApi(`${process.env.REACT_APP_POST}`, requestBodyCharts);
    })();
  };

  return (
    <DelimitBox customStyle={{ width: "436px", ml: 2, height: "70vh", overflow: "auto", maxWidth: "40vw" }}>
      <BasicHeader title="Insights">
        <IconButton aria-label="refresh" onClick={handleClick}>
          <RefreshIcon />
        </IconButton>
      </BasicHeader>

      {insights.length > 0 ? (
        insights.map(({ insights_name, description, valueQuery, category }: IInsightAlertSetupProps, i: number) => (
          <InsightAlert
            alertType={AlertTypeEnum.SUCCESS}
            message={description}
            value={valueQuery}
            category={category}
            key={i}
          />
        ))
      ) : (
        <Typography sx={{ flex: 1 }}>There are currently no insights available!</Typography>
      )}
      <Stack direction="row" sx={{ mt: "42px", mb: 4 }}></Stack>
    </DelimitBox>
  );
};

export default Insights;
