/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import BasicHeader from "../common/BasicHeader";
import Table from "../common/table/Table";
import DelimitBox from "../common/DelimitBox";
import { ITableData, ITableRowFrame } from "../common/table/types";
import { selfServiceTabs } from "../configurationSettings/constants";
import InputFilters from "./InputFilters";
import { flexibleList } from "./constants";
import { getApi } from "../../service";

interface ICustomerDetailsProps {}

const CustomerDetails: React.FC<ICustomerDetailsProps> = () => {
  const navigate = useNavigate();
  const formDetails = useForm({ defaultValues: { status: "ERROR" } });
  const { control } = formDetails;

  const [transactionData, setTransactionData] = useState<ITableData[]>([]);
  const [flexibleTableColumn, setFlexibleTableColumn] = useState<ITableRowFrame[]>([]);

  const watchForm = useWatch({ control });

  useEffect(() => {
    setFlexibleTableColumn(
      flexibleList.map((e) => {
        const keyTab = selfServiceTabs.find((ele) => ele + "Number" === e.name);

        if (keyTab) {
          return {
            ...e,
            onClick: (id: any) => {
              const { orderNumber, deliveryNumber, invoiceNumber } = id;
              navigate(
                `/self-service/details/${keyTab}?order=${orderNumber}&delivery=${deliveryNumber}&invoice=${invoiceNumber}`
              );
            },
          };
        }

        return e;
      })
    );
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(async () => {
      const res = await getApi(`${process.env.REACT_APP_CHART_URL}?customerReport=true&chartType=cc`);
      setTransactionData(res.data.customerDetailsData);
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [watchForm]);

  return (
    <DelimitBox customStyle={{ p: 0, mt: 2 }}>
      <BasicHeader title="Customers" customStyle={{ p: "20px" }} />
      <InputFilters formDetails={formDetails} />

      <Table
        transactionData={transactionData}
        isViewMoreBtnExist={true}
        flexibleTableColumn={flexibleTableColumn}
        setFlexibleTableColumn={setFlexibleTableColumn}
      />
    </DelimitBox>
  );
};

export default CustomerDetails;
