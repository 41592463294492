import { Box, IconButton, Modal, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

import BasicHeader from "../../common/BasicHeader";

const InfoModal = ({ isModalOpen, setIsModalOpen, header, text }) => {
  return (
    <Modal
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          borderRadius: 2,
        }}
      >
        <BasicHeader title={header} customStyle={{ paddingBottom: "24px" }}>
          <IconButton onClick={() => setIsModalOpen(false)}>
            <Close />
          </IconButton>
        </BasicHeader>
        <Typography>{text}</Typography>
      </Box>
    </Modal>
  );
};

export default InfoModal;
