import React, { useState } from "react";
import { Box, Stack, styled, Table as MuiTable, TableContainer } from "@mui/material";

import THeader from "./THeader";
import { ITableData, ITableRowFrame, OrderEnum } from "./types";
import TBody from "./TBody";
import TFooter from "./TFooter";
import { ChevronRightOutlined } from "@mui/icons-material";
import CustomizeColumnsDrawer from "./customizeColumns/CustomizeColumnsDrawer";

const SliderButton = styled(Box)({
  width: "32px",
  height: "32px",
  borderRadius: "50%",
  backgroundColor: "#0C8CAA",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  position: "absolute",
  right: "10px",
  top: "50%",
  opacity: 0.5,
  cursor: "pointer",
  transform: "translateY(-50%)",
  "&:hover": {
    opacity: 1,
  },
});

interface ITableProps {
  transactionData: ITableData[];
  isViewMoreBtnExist?: boolean;
  openCustomizeColumns?: boolean;
  setOpenCustomizeColumns?: React.Dispatch<React.SetStateAction<boolean>>;
  fixedTableColumn?: ITableRowFrame[];
  setFixedTableColumn?: React.Dispatch<React.SetStateAction<ITableRowFrame[]>>;
  flexibleTableColumn: ITableRowFrame[];
  setFlexibleTableColumn?: React.Dispatch<React.SetStateAction<ITableRowFrame[]>>;
  page?: number;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  size?: number;
  setSize?: React.Dispatch<React.SetStateAction<number>>;
  count?: number;
}

const Table: React.FC<ITableProps> = ({
  transactionData,
  isViewMoreBtnExist,
  openCustomizeColumns,
  setOpenCustomizeColumns,
  fixedTableColumn,
  setFixedTableColumn,
  flexibleTableColumn,
  setFlexibleTableColumn,
  page = 1,
  setPage = () => {},
  size = 10,
  setSize = () => {},
  count = 0,
}) => {
  const divRef = React.useRef<HTMLDivElement>(null);

  const [order, setOrder] = useState<OrderEnum>(OrderEnum.ASC);
  const [orderBy, setOrderBy] = useState<string>("");

  const handleRequestSort = (orderName: string) => {
    const isAsc = orderBy === orderName && order === OrderEnum.ASC;
    setOrder(isAsc ? OrderEnum.DEC : OrderEnum.ASC);
    setOrderBy(orderName);
  };

  return (
    <>
      <Box sx={{ width: "100%", overflow: "hidden" }}>
        <Stack direction={"row"} position="relative">
          {isViewMoreBtnExist && (
            <SliderButton
              onClick={() => {
                if (divRef && divRef.current) {
                  divRef.current.scrollLeft += 400;
                }
              }}
            >
              <ChevronRightOutlined />
            </SliderButton>
          )}
          {fixedTableColumn && (
            <MuiTable aria-labelledby="tableTitle" sx={{ width: "auto" }}>
              <THeader
                tableCellData={fixedTableColumn}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TBody
                tableCellData={fixedTableColumn}
                transactionData={transactionData}
                order={order}
                orderBy={orderBy}
              />
            </MuiTable>
          )}

          <TableContainer ref={divRef}>
            <MuiTable aria-labelledby="tableTitle">
              <THeader
                tableCellData={flexibleTableColumn}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TBody
                tableCellData={flexibleTableColumn}
                transactionData={transactionData}
                order={order}
                orderBy={orderBy}
              />
            </MuiTable>
          </TableContainer>
        </Stack>
        <TFooter page={page} setPage={setPage} size={size} setSize={setSize} count={count} />
      </Box>
      {fixedTableColumn &&
        openCustomizeColumns &&
        setOpenCustomizeColumns &&
        setFixedTableColumn &&
        setFlexibleTableColumn && (
          <CustomizeColumnsDrawer
            openCustomizeColumns={openCustomizeColumns}
            setOpenCustomizeColumns={setOpenCustomizeColumns}
            fixedTableColumn={fixedTableColumn}
            setFixedTableColumn={setFixedTableColumn}
            flexibleTableColumn={flexibleTableColumn}
            setFlexibleTableColumn={setFlexibleTableColumn}
          />
        )}
    </>
  );
};

export default Table;
