import React, { useState } from "react";
import { Container, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useUser } from "../providers/UserProvider";
import Dropdown from "../components/common/inputs/Dropdown";
import OrdersOverview from "../components/ordersOverview/OrdersOverview";
import Insights from "../components/insights/Insights";
import Customers from "../components/customersOverview/CustomersOverview";
import ProductLines from "../components/productLines/ProductLines";
import Transactions from "../components/transactions/Transactions";
import { countryDetails } from "../components/common/constants/countries";
import { defaultValues, tableLayoutDashboard } from "../components/transactions/constants";
import { ChartTypeEnum } from "../components/common/chart/constants";

interface IDashboardProps {}

const Home: React.FC<IDashboardProps> = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [country, setCountry] = useState({});
  const tableLayout = tableLayoutDashboard(t);
  if (user.countries && user.countries.length > 1)
    tableLayout.filterLayout.push({
      type: "MultiSelectDropdown",
      label: "Select Country",
      placeholder: "Select Country",
      optionData: user.countries.map((item) => {
        return {
          value: item,
          label: countryDetails[item].name,
        };
      }),
      customStyle: { width: "260px" },
      formKey: "countries",
    });

  return (
    <Container maxWidth="xl">
      <Stack direction="row" sx={{ mt: "42px", mb: 4 }}>
        <Typography variant="h1" sx={{ mr: "12px" }}>
          {t("WELCOME_BACK")} {user.name}! {t("VIEW_DATA")} {user.soldTo ? user.soldTo.length : 1} SoldTo account(s) for
          a week
        </Typography>

        <Dropdown
          optionData={user.countries.map((item) => {
            return {
              value: item,
              label: countryDetails[item].name,
            };
          })}
          preSelectedValue={{
            value: user.countries[0],
            label: countryDetails[user.countries[0]].name,
          }}
          handleValueChange={setCountry}
        />
      </Stack>
      <Transactions {...tableLayout} header="Transactions to Resolve" defaultValues={defaultValues} />
      <Stack direction="row" sx={{ my: 2 }}>
        <OrdersOverview />
        <Insights />
      </Stack>
      <Stack direction="row">
        <Customers type={ChartTypeEnum.BAR} />
        <ProductLines />
      </Stack>
    </Container>
  );
};

export default Home;
