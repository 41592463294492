import { useState } from "react";
import { Button, Stack } from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useUser } from "../../../providers/UserProvider";
import PerformanceMultiSelect from "./PerformanceMultiSelect";
import { btnPrimary, btnSec } from "../../insightsv2/constants";
import DateSelect from "../../common/inputs/DateSelect";
import { IProductOptions, IProductWatchForm } from "../types";
import { dateValues } from "./constants";
import { integrationCatalogUsageTracking } from "../../../helper";

interface IPerformanceFilterProps {
  formDetails: UseFormReturn<IProductWatchForm, any, undefined>;
  toggleSearch: () => void;
  formData?: IProductWatchForm;
  product: IProductOptions;
}

const PerformanceFilter: React.FC<IPerformanceFilterProps> = ({ toggleSearch, formDetails, formData, product }) => {
  const { t } = useTranslation();
  const { reset } = formDetails;
  const { user } = useUser();
  const [isClear, setIsClear] = useState(false);

  const handleClearInputs = () => {
    setIsClear(!isClear);
    toggleSearch();
    reset();
  };

  return (
    <Stack direction="row" flexWrap="wrap" pb="20px" columnGap={2} rowGap={3} component="form">
      <PerformanceMultiSelect formDetails={formDetails} isClear={isClear} product={product} />

      <DateSelect
        dateOptions={dateValues(t)}
        formDetails={formDetails}
        isClear={isClear}
        duration={formData?.duration || ""}
      />

      <Button variant="contained" sx={btnPrimary} onClick={() => {
         toggleSearch();integrationCatalogUsageTracking(user,"IC_Performance_search_clicked");}} children={t("SEARCH")} />
         
      <Button variant="outlined" sx={btnSec} onClick={handleClearInputs} children={t("RESET")} />
    </Stack>
  );
};

export default PerformanceFilter;
