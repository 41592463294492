/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  Chart as ChartJS,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PluginChartOptions,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import { AlertTypeEnum, useAlert } from "../../../providers/AlertProvider";
import { useUser } from "../../../providers/UserProvider";
import { postApi } from "../../../service";
import {
  orderInsightPieData,
  orderInsightPieOptionsBottom,
  orderInsightPieOptionsRight,
  orderInsightPluginsBottom,
  orderInsightPluginsRight,
} from "../constants";
import Loader from "../../common/loader/Loader";
import { countryDetails } from "../../common/constants/countries";
import { currencyFormatter } from "../../../helper";

ChartJS.register(LinearScale, ArcElement, Title, Tooltip, Legend);

const OrderFranchiseChart = ({ formData, onSearch, loadDefaultValues }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { addAlert } = useAlert();
  const currency = countryDetails[user.countries[0]].currency;

  const [resData, setResData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);
  const [topFranchise, setTopFranchise] = useState<{
    franchiseName: string;
    netValue: string;
    franchiseCode: string;
  } | null>(null);

  const dataFetch = async () => {
    try {
      setIsLoading(true);
      if (
        formData.duration === "custom" &&
        (!formData.startDate ||
          !formData.endDate ||
          new Date(formData.startDate) > new Date(formData.endDate))
      ) {
        addAlert({
          message: "Error in start date or end date!",
          type: AlertTypeEnum.ERROR,
        });
      } else {
        const res = await postApi(`${process.env.REACT_APP_POST}`, {
          api_name: "OrderInsightBySaleForFranchise",
          org: process.env.REACT_APP_ORG,
          sector: process.env.REACT_APP_SECTOR,
          countries: user.countries,
          ...formData,
        });

        const { data, status, message } = res.data;
        if (!status) {
          addAlert({ message, type: AlertTypeEnum.ERROR });
        }
        if (loadDefaultValues(res)) {
          return;
        }

        if (!data) {
          setResData([]);
        } else {
          const labels = data.map(
            ({ franchiseName, franchiseCode }) =>
              `${franchiseName}(${franchiseCode})`
          );

          setTopFranchise(
            data.reduce((prev, current) =>
              prev && Number(prev.netValue) > Number(current.netValue)
                ? prev
                : current
            )
          );

          setResData({
            ...orderInsightPieData,
            labels,
            datasets: [
              {
                ...orderInsightPieData.datasets[0],
                data: data.map(({ netValue }) => netValue),
              },
            ],
          });
        }
      }
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    dataFetch();
  }, [onSearch]);

  const optionsRight = {
    ...orderInsightPieOptionsRight,
    ...(orderInsightPluginsRight(currency) as PluginChartOptions<"pie">),
  };
  const optionsBottom = {
    ...orderInsightPieOptionsBottom,
    ...(orderInsightPluginsBottom(currency) as PluginChartOptions<"pie">),
  };

  return (
    <Box flex={1}>
      <Typography variant="h2" mt={2}>
        {t("ORDER_VALUE_PLACED_FRANCHISE")}
      </Typography>

      {isLoading ? (
        <Loader />
      ) : resData.datasets !== undefined && resData.datasets.length ? (
        <>
          {resData?.datasets[0]?.data.length <= 3 ? (
            <Box
              sx={{
                height: 500,
                display: "flex", // Use flexbox
                flexDirection: "column", // Align items in a column
                justifyContent: "center", // Center vertically
                alignItems: "center",
              }}>
              <Box sx={{ height: 350 }}>
                <Pie data={resData} options={optionsBottom} />{" "}
              </Box>
            </Box>
          ) : (
            <Box sx={{ height: 500, width: 500 }}>
              <Pie data={resData} options={optionsRight} />
            </Box>
          )}

          {topFranchise && (
            <>
              {resData?.datasets[0]?.data.length <= 3 ? (
                <Typography variant="h3" sx={{ m: 0, mt: -1.5 }}>
                  <Typography
                    variant="h3"
                    sx={{ m: 0, display: "flex", justifyContent: "center" }}>
                    {topFranchise.franchiseName}({topFranchise.franchiseCode})
                  </Typography>
                  <Typography
                    sx={{
                      m: 0,
                      whiteSpace: "nowrap",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                    }}>
                    {t("FRANCHISES_HAS_PLACED_HIGHEST_ORDER")}{" "}
                    <Typography variant="h3" sx={{ m: 0 }}>
                      {currency}{" "}
                      {currencyFormatter(Number(topFranchise.netValue))}
                    </Typography>
                  </Typography>
                </Typography>
              ) : (
                <Typography variant="h3" sx={{ m: 0, whiteSpace: "nowrap" }}>
                  {topFranchise.franchiseName}({topFranchise.franchiseCode})
                  <Typography component="span">
                    {" "}
                    {t("FRANCHISES_HAS_PLACED_HIGHEST_ORDER")}{" "}
                  </Typography>
                  {currency} {currencyFormatter(Number(topFranchise.netValue))}
                </Typography>
              )}
            </>
          )}
        </>
      ) : (
        <Box sx={{ mt: 5 }}>
          <Typography component="span">{t("NO_DATA_FOUND")}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default OrderFranchiseChart;
