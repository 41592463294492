import { ChartOptions } from "chart.js";
import { barDataSet, lineDataSet } from "../ordersOverview/constants";
import { DayOption, DayType } from "../ordersOverview/types";

export const dayOptions = [
  { value: DayType.TODAY, label: DayOption.TODAY },
  { value: DayType.SEVEN, label: DayOption.SEVEN },
  { value: DayType.THIRTY, label: DayOption.THIRTY },
  { value: DayType.THREE_MONTHS, label: DayOption.THREE_MONTHS },
  { value: DayType.SIX_MONTHS, label: DayOption.MONTHLY },
];

export const tabData = [{ label: "Most Sales" }, { label: "Least Sales" }];

export const options: ChartOptions = {
  plugins: {
    legend: { position: "right", align: "end" },
    tooltip: {
      callbacks: {
        label: (tooltipItem: any): string => `$${tooltipItem.formattedValue}`,
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: { display: false },
      stacked: true,
    },

    y: {
      min: 0,
      // max: 100,
      ticks: {
        // stepSize: 100000,
        callback: (value: any): string => "$" + parseInt(value) / 1000 + "k",
      },
      stacked: true,
    },
  },
};

export const orderOverViewChartDataOptions = {
  dataSet: barDataSet,
  dayType: "accountId",
  dataKey: ["totalSales"],
  backgroundColor: ["#00B5E2"],
};

export const orderOverViewChartDataLineOptions = {
  dataSet: lineDataSet,
  dayType: "accountId",
  dataKey: ["totalSales"],
  backgroundColor: ["#0025A5A5"],
};
