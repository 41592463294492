import { Container } from "@mui/material";
import TransactionDetails from "../components/transactionDetails/TransactionDetails";

const TransactionDetailsPage = () => {
  return (
    <Container maxWidth="xl">
      <TransactionDetails />
    </Container>
  );
};

export default TransactionDetailsPage;
