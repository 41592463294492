import React, { createContext, useContext, useState } from "react";
import { IChildrenProps } from "../components/common/types";

export interface IConfigContext {
  configState: { [key: string]: any };
  setConfigState: (configState: { [key: string]: any }) => void;
}

export const ConfigContext = createContext<IConfigContext>({
  configState: {},
  setConfigState: () => {},
});

export const useConfig = () => {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error("useConfig must be used within a ConfigProvider");
  }
  return context;
};

export const ConfigProvider: React.FC<IChildrenProps> = ({ children }) => {
  const [configState, setConfigState] = useState<{ [key: string]: any }>({});

  return <ConfigContext.Provider value={{ configState, setConfigState }}>{children}</ConfigContext.Provider>;
};
