import { Circle } from "@mui/icons-material";

import { IOptionData } from "../common/inputs/types";
import { ITableRowFrame, StatusTypeEnum } from "../common/table/types";

export const flexibleList: ITableRowFrame[] = [
  { name: "Account Id", label: "Account Id" },
  { name: "Account Name", label: "Account Name" },
  { name: "email", label: "Email" },
  { name: "last order date", type: "date", label: "Last Order Date" },
  { name: "Total Sales", label: "Total Sales(AUD)" },
  { name: "Order Created", label: "Order Created" },
  { name: "Order Invoiced", label: "Order Invoiced" },
  { name: "Successful Transactions", label: "Successful Transactions" },
  { name: "Failed Transactions", label: "Failed Transactions" },
];

export const optionData: IOptionData[] = [
  { value: "Account Name", label: "Account Name" },
  { value: "Account Id", label: "Account Id" },
];

export const rowsPerPageOptions = [
  { label: "5 Items", value: 5 },
  { label: "10 Items", value: 10 },
  { label: "20 Items", value: 20 },
  { label: "All", value: -1 },
];

export const droppableMap = [
  { droppableId: "fixed-list", titleHeader: "Fixed Column" },
  { droppableId: "flexible-list", titleHeader: "Drag Column" },
];

export const transactionStatus: IOptionData[] = [
  { value: "", label: "Select All" },
  {
    value: StatusTypeEnum.ERROR,
    label: "Error",
    icon: <Circle sx={{ color: "#E1342C", fontSize: "10px !important" }} />,
  },
  {
    value: StatusTypeEnum.SUCCESS,
    label: "Success",
    icon: <Circle sx={{ color: "#0EB58E", fontSize: "10px !important" }} />,
  },
];
