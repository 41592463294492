import { createContext, Dispatch, FC, SetStateAction, useContext, useState } from "react";
import { IChildrenProps } from "../components/common/types";

interface IUserState {
  email: string;
  name: string;
  role: string;
  soldTo: string[];
  soldToDetails: { accountId: string; accountName: string; customerGroup: string }[];
  countries: string[];
  userName: string;
}

export interface IUserContext {
  user: IUserState;
  setUser: Dispatch<SetStateAction<IUserState>>;
  isBannerShowed: boolean;
  setIsBannerShowed: Dispatch<SetStateAction<boolean>>;
}

export const UserContext = createContext<IUserContext>({
  user: {
    email: "",
    name: "",
    role: "",
    soldTo: [""],
    soldToDetails: [{ accountId: "", accountName: "", customerGroup: "" }],
    countries: [""],
    userName: "",
  },
  setUser: () => {},
  isBannerShowed: true,
  setIsBannerShowed: () => {},
});

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

export const UserProvider: FC<IChildrenProps> = ({ children }) => {
  const [user, setUser] = useState({
    email: "",
    name: "",
    role: "",
    soldTo: [""],
    soldToDetails: [{ accountId: "", accountName: "", customerGroup: "" }],
    countries: [""],
    userName: "",
  });
  const [isBannerShowed, setIsBannerShowed] = useState<boolean>(true);

  return (
    <UserContext.Provider value={{ user, setUser, isBannerShowed, setIsBannerShowed }}>{children}</UserContext.Provider>
  );
};
