import { useEffect, useState } from "react";
import { Box, Stack, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";

interface IInterfaceFilterProps {
  textSearch: string;
  setTextSearch: (val: string) => void;
}

const InterfaceFilter: React.FC<IInterfaceFilterProps> = ({ textSearch, setTextSearch }) => {
  return (
    <Box sx={{ display: "flex" }} component="form">
      <Search sx={{ color: "action.active", mr: 1, my: 0.5 }} />
      <TextField
        id="search"
        name={`input_${Math.random().toString(36).substr(2, 9)}`}
        type="text"
        variant="standard"
        placeholder="find"
        autoComplete="off"
        value={textSearch}
        onChange={(event) => setTextSearch(event.target.value)}
      />
    </Box>
  );
};

export default InterfaceFilter;
