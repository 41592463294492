import { useState } from "react";
import { Divider, Stack, Typography } from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import BasicHeader from "../../common/BasicHeader";
import OrderFranchiseChart from "./OrderFranchiseChart";
import OrderLimit from "./OrderLimit";
import PurchaseTrend from "./PurchaseTrend";
import { countryDetails } from "../../common/constants/countries";
import OrderQuantity from "./OrderQuantity";
import InsightFilter from "../InsightFilter";
import { useUser } from "../../../providers/UserProvider";
import BasicTabs from "../../common/BasicTabs";
import { IWatchForm } from "../productInsights";

const OrderInsights = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const formDetails = useForm({
    defaultValues: {
      duration: "1 Month",
      soldTos: user.soldToDetails ? user.soldToDetails.map(({ accountId }) => accountId) : [],
    },
  });
  const watchForm: IWatchForm = useWatch({ control: formDetails.control });

  const customInsightMarkets = ["AU", "NZ"];

  const [onSearch, setOnSearch] = useState(false);
  const [purchaseTrendMsg, setPurchaseTrendMsg] = useState("");
  const calculateDuration = () => {
    const customeChinaObj = {
      "1 Month": "一个月",
      "3 Month": "三个月",
      "6 Month": "六个月",
    };

    if (watchForm.duration === "custom") {
      const startDate = dayjs(watchForm?.startDate).format("YYYY-MM-DD");
      const endDate = dayjs(watchForm?.endDate).format("YYYY-MM-DD");
      // const diffInDays = endDate.diff(startDate, "day");

      if (user.countries[0] === "CN") {
        return startDate + " 到 " + endDate;
      }
      return startDate + " to " + endDate;
    } else {
      if (user.countries[0] === "CN") {
        return customeChinaObj[watchForm.duration || "1 Month"];
      }
      if (watchForm.duration === "3 Month" || watchForm.duration === "6 Month") {
        return watchForm.duration + "s";
      } else {
        return watchForm.duration;
      }
    }
  };

  const toggleSearch = () => {
    setOnSearch(!onSearch);
  };

  const purchaseTrendDuration = () => {
    const duration = calculateDuration();
    setPurchaseTrendMsg(
      `${t("VIEWING_PERCHASE_TREND")} ${duration}  ${watchForm?.franchises?.length || watchForm?.soldTos?.length ? `${t("SELECTED_FRANCHISE_CUSTOMER")}` : `${t("FOR_ALL_FRANCHISES")}`}`
    );
  };

  const loadDefaultValues = (res) => {
    purchaseTrendDuration();
    if ((res.data.data?.length === 0 || res.data.data?.length === undefined) && watchForm.duration === "1 Month" && !watchForm.franchises && !watchForm.soldTos) {
      formDetails.setValue("duration", "3 Month");
      toggleSearch();
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <BasicHeader title={t("ORDER_INSIGHTS")} customStyle={{ pb: "20px" }} />
      <InsightFilter filedMessage="Order_insights" toggleSearch={toggleSearch} formDetails={formDetails} formData={watchForm} />
      <Stack direction="row" gap={6}>
        <OrderFranchiseChart onSearch={onSearch} formData={watchForm} loadDefaultValues={loadDefaultValues} />
        <Stack flex={1} sx={{ height: 635 }}>
          <Typography variant="h2" mt={2}>
            {t("PURCHASE_TREND")}
          </Typography>

          {watchForm.duration === "custom" && (!watchForm.startDate || !watchForm.endDate || new Date(watchForm.startDate) > new Date(watchForm.endDate)) ? (
            <BasicTabs data={[{ label: t("ORDER_VALUE") }, { label: t("ORDER_QUANTITY") }]} components={[]} currentTab={0} />
          ) : (
            <BasicTabs
              data={[{ label: t("ORDER_VALUE") }, { label: t("ORDER_QUANTITY") }]}
              components={[<PurchaseTrend onSearch={onSearch} formData={watchForm} />, <OrderQuantity onSearch={onSearch} formData={watchForm} />]}
              currentTab={0}
            />
          )}

          <Typography mt={6.5}>{purchaseTrendMsg}</Typography>
        </Stack>
      </Stack>

      <Divider />

      {!customInsightMarkets?.some((e) => countryDetails[e].countryCode === user.countries[0]) ? (
        <></>
      ) : (
        <>
          <OrderLimit onSearch={onSearch} formData={watchForm} />
        </>
      )}
    </>
  );
};

export default OrderInsights;
