/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAlert, AlertTypeEnum } from "../../providers/AlertProvider";
import { useUser } from "../../providers/UserProvider";
import { postApi } from "../../service";
import Loader from "../common/loader/Loader";
import { countryDetails } from "../common/constants/countries";
import { tableHeadSx, tableBodySx } from "../insightsv2/constants";
import { currencyFormatter } from "../../helper";

const ProductInsightCard = ({ onSearch, formData, loadDefaultValues }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { addAlert } = useAlert();
  const currency = countryDetails[user.countries[0]].currency;

  const [resData, setResData] =
    useState<
      { productName: string; netValue: string; productCode: string }[]
    >();
  const [isLoading, setIsLoading] = useState(true);

  const handleSearch = async () => {
    try {
      setIsLoading(true);
      if (
        formData.duration === "custom" &&
        (!formData.startDate ||
          !formData.endDate ||
          new Date(formData.startDate) > new Date(formData.endDate))
      ) {
        addAlert({
          message: "Error in start date or end date!",
          type: AlertTypeEnum.ERROR,
        });
      } else {
        const arr = await postApi(`${process.env.REACT_APP_POST}`, {
          api_name: "HomeInsightTop5SellingProducts",
          org: process.env.REACT_APP_ORG,
          sector: process.env.REACT_APP_SECTOR,
          countries: user.countries,
          ...formData,
        });

        const { data, status, message } = arr.data;
        if (!status) {
          addAlert({ message, type: AlertTypeEnum.ERROR });
        }

        if (loadDefaultValues(arr)) {
          return;
        }

        setResData(data);
      }
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleSearch();
  }, [onSearch]);

  return (
    <Box flex={1} sx={{ mt: 2 }}>
      <Typography variant="h2" children={t("TOP_SELLING_PRODUCT_IN_MARKET")} />
      {isLoading ? (
        <Loader />
      ) : (
        <TableContainer sx={{ width: "100%", mt: 2 }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ ...tableHeadSx, minWidth: 80 }} align="left">
                  {t("NO")}
                </TableCell>
                <TableCell sx={tableHeadSx} align="left">
                  {t("PRODUCT")}
                </TableCell>
                <TableCell sx={tableHeadSx} align="left">
                  {t("VALUE")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resData &&
                resData.map((item, index) => (
                  <TableRow key={item.productName}>
                    <TableCell
                      sx={tableBodySx}
                      align="left"
                      component="th"
                      scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell sx={tableBodySx} align="left">
                      {item.productName}({item.productCode})
                    </TableCell>
                    <TableCell sx={tableBodySx} align="left">
                      {currency} {currencyFormatter(Number(item.netValue))}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default ProductInsightCard;
