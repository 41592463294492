import { FC, useEffect, useState } from "react";
import { Chart as ChartJS, LinearScale, Title, Tooltip, Legend, ArcElement } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { UseFormReturn } from "react-hook-form";

import Loader from "../../common/loader/Loader";
import { postApi } from "../../../service";
import { errorChartOption, reqErrorObj } from "./constants";
import { IErrorRateAnalytics, IMsgValue, IProductWatchForm } from "../types";
import { handleErrorChartData } from "./helper";
import { useEnvironment } from "../../../providers/EnvironmentProvider";

ChartJS.register(LinearScale, ArcElement, Title, Tooltip, Legend);

interface IErrorCodeAnalyticsChartProps {
  formDetails: UseFormReturn<IProductWatchForm, any, undefined>;
  isSearched: boolean;
}
interface IMaxErrorCode extends IMsgValue {
  errorCode: string;
}

const ErrorCodeAnalyticsChart: FC<IErrorCodeAnalyticsChartProps> = ({ formDetails, isSearched }) => {
  const { getValues } = formDetails;
  const { environmentState } = useEnvironment();

  const [resData, setResData] = useState<IErrorRateAnalytics | null>(null);
  const [chartTitle, setChartTitle] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      let res: {
        data: { data: { errorCodesCount: IErrorRateAnalytics; maxErrorCode: IMaxErrorCode } };
        status: number;
      } = await postApi(`${process.env.REACT_APP_POST}`, {
        ...reqErrorObj,
        ...getValues(),
        env: environmentState ? environmentState : process.env.PERFORMANCE_INDEX || null,
      });

      try {
        setResData(res.data.data.errorCodesCount);
        setChartTitle(res.data.data.maxErrorCode.message + " - " + res.data.data.maxErrorCode.errorCode);
      } catch (err) {
        console.error(err);
      }
      setIsLoading(false);
    })();
  }, [isSearched]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>{resData ? <Doughnut data={handleErrorChartData(resData)} options={errorChartOption(chartTitle)} /> : <></>}</>
  );
};

export default ErrorCodeAnalyticsChart;
