import { postApi } from "./service";

export const matchPathname = (path: string, milestone: string) => path.split("/").some((e) => e === milestone);

export const handleKeysFromParams = (param: string): { [key: string]: string } =>
  param
    .substring(1)
    .split("&")
    .reduce((ac, cv) => {
      const array: string[] = cv.split("=");
      return { ...ac, [array[0]]: array[1] };
    }, {});

export const convertToDate = (key, value) => {
  if (key.includes("Date") && typeof value === "string" && value.length === 8) {
    if (Number(value) === 0) {
      return null;
    }
    return `${value.substring(0, 4)}-${value.substring(4, 6)}-${value.substring(6)}`;
  }

  if (key.includes("Time") && typeof value === "string" && value.length === 6) {
    if (Number(value) === 0) {
      return null;
    }
    return `${value.substring(0, 2)}:${value.substring(2, 4)}:${value.substring(4)}`;
  }

  return value;
};

export const integrationCatalogUsageTracking = async (user, msg: string) => {
  try {
    const message = {
      event: msg,
      customer_sold_to: "-",
      rec_updated_timestamp: "-",
      country: user.countries[0],
    };
    await postApi(`${process.env.REACT_APP_BANNER_USAGE}`, message);
    console.log(message);
  } catch (error) {
    console.log("Failed to send integration catalog usage message");
  }
};

export const currencyFormatter = (value: number) => value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

export const usageTrackingMessage = async (user, msg: string) => {
  try {
    const message = {
      event: msg,
      customer_sold_to: "-",
      rec_updated_timestamp: "-",
      country: user.countries[0],
    };
    await postApi(`${process.env.REACT_APP_BANNER_USAGE}`, message);
  } catch (error) {
    console.log("Failed to track Customer Insights usage");
  }
};
