/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box, ClickAwayListener, SxProps, Typography } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { InputWrapper, Listbox, StyledTag } from "./styles";
import { useUser } from "../../providers/UserProvider";

interface ICustomerMultiSelect {
  setValue: UseFormSetValue<any>;
  customStyle?: SxProps;
  isClear?: boolean;
}

interface IOptionData {
  key: string;
  value: string;
}

const CustomerMultiSelect: React.FC<ICustomerMultiSelect> = ({ setValue, customStyle = {}, isClear }) => {
  const { t } = useTranslation();
  const { user } = useUser();

  const [selectedValues, setSelectedValues] = useState<IOptionData[]>([]);
  const [selectedValueObject, setSelectedValueObject] = useState<{ [key in string]: IOptionData }>({});
  const [options] = useState<IOptionData[]>(
    user.soldToDetails.map(({ accountId, accountName }) => ({ key: accountId, value: accountName }))
  );
  const [inputValue, setInputValue] = useState("");
  const [clearState, setClearState] = useState(isClear);

  useEffect(() => {
    if (selectedValues.length) {
      setValue(
        "soldTos",
        selectedValues.map(({ key }) => key)
      );
    } else {
      setValue("soldTos", user.soldToDetails ? user.soldToDetails.map(({ accountId }) => accountId) : []);
    }
  }, [selectedValues]);

  useEffect(() => {
    if (clearState !== isClear) {
      setSelectedValues([]);
      setSelectedValueObject({});
      setClearState(!clearState);
      setInputValue("");
    }
  }, [isClear]);

  const onDelete = (option, index) => {
    const obj = { ...selectedValueObject };
    delete obj[option.key];

    setSelectedValues([...selectedValues.slice(0, index), ...selectedValues.slice(index + 1)]);
    setSelectedValueObject(obj);
    setInputValue("");
  };

  const onSelect = ({ key, value }) => {
    if (selectedValueObject?.[key]) {
      onDelete(
        { key, value },
        selectedValues.findIndex((e) => e.key === key)
      );
    } else {
      setSelectedValues([...selectedValues, { key, value }]);
      setSelectedValueObject({ ...selectedValueObject, [key]: { key, value } });
      setInputValue("");
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setInputValue("")}>
      <Box sx={customStyle}>
        <div>
          <Typography variant="h3">{t("CUSTOMER")}</Typography>
          <InputWrapper>
            {selectedValues.map((option: IOptionData, index: number) => (
              <StyledTag key={option.key}>
                <span>{option.value}</span> <Close onClick={() => onDelete(option, index)} />
              </StyledTag>
            ))}
            <input
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={selectedValues.length ? "" : t("SEARCH_HERE")}
              value={inputValue}
            />
          </InputWrapper>
        </div>
        {inputValue && options.length ? (
          <>
            <Listbox>
              {options.map(({ value, key }) => {
                const isMatch =
                  value.toLowerCase().search(inputValue.toLowerCase().trim()) >= 0 ||
                  key.toLowerCase().search(inputValue.toLowerCase().trim()) >= 0;

                if (isMatch) {
                  return (
                    <li
                      onClick={() => onSelect({ value, key })}
                      aria-selected={selectedValueObject?.[key as string] ? "true" : "false"}
                      key={key}
                    >
                      <span>{value}</span> <Check fontSize="small" />
                    </li>
                  );
                }

                return null; // Return null for non-matching items.
              })}

              {inputValue.length > 0 &&
                options.every(
                  ({ value, key }) =>
                    value.toLowerCase().search(inputValue.toLowerCase().trim()) === -1 &&
                    key.toLowerCase().search(inputValue.toLowerCase().trim()) === -1
                ) && <li style={{ color: "#e91800" }}>You have entered Invalid Customer Name /Customer ID</li>}
            </Listbox>
          </>
        ) : (
          <></>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default CustomerMultiSelect;
