import { useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Box, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

import BasicHeader from "../../common/BasicHeader";
import MarketInsightFilter from "../../marketInsights/MarketInsightFilter";
import OrderInsightCard from "../../marketInsights/OrderInsightCard";
import ProductInsightCard from "../../marketInsights/ProductInsightCard";
import { IWatchForm } from "../productInsights";
import { useUser } from "../../../providers/UserProvider";

const MarketInsights = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const formDetails = useForm({
    defaultValues: {
      duration: "1 Month",
      soldTos: user.soldToDetails ? user.soldToDetails.map(({ accountId }) => accountId) : [],
    },
  });
  const watchForm: IWatchForm = useWatch({ control: formDetails.control });

  const [onSearch, setOnSearch] = useState(false);
  
  const toggleSearch = () => {
    setOnSearch(!onSearch);
  };
  const loadDefaultValues = (res) => {
    if (
      (res.data.data?.length === 0 || res.data.data?.length === undefined) &&
      watchForm.duration === "1 Month" &&
      !watchForm.franchises &&
      !watchForm.soldTos
    ) {
      formDetails.setValue("duration", "3 Month");
      toggleSearch();
      return true;
    }
    return false;
  };

  return (
    <>
      <BasicHeader title={t("MARKET_INSIGHT")} customStyle={{ pb: "20px" }} />
      <MarketInsightFilter toggleSearch={toggleSearch} formDetails={formDetails} formData={watchForm} />
      <Box>
        <Stack direction="row" gap={4}>
          <ProductInsightCard onSearch={onSearch} formData={watchForm} loadDefaultValues={loadDefaultValues} />
          <OrderInsightCard onSearch={onSearch} formData={watchForm} />
        </Stack>
      </Box>
    </>
  );
};

export default MarketInsights;
