import { Alert, Container, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import InsightsV2 from "../components/insightsv2";
import { countryDetails } from "../components/common/constants/countries";
import { useUser } from "../providers/UserProvider";

const InsightsPageV2 = () => {
  const { t } = useTranslation();
  const { user, isBannerShowed, setIsBannerShowed } = useUser();
  console.log(user, "this is user");
  return (
    <Container maxWidth="xl">
      {/* <NavigateBack headerText="Insights" /> */}
      {isBannerShowed && user.countries.some((country) => country === "CN") && (
        <Alert severity="warning" onClose={() => setIsBannerShowed(false)} icon={false}>
          欢迎访问客户洞察界面，现在是试运行模式！您的任何意见对于我们提升用户体验非常重要。
          目前本页面显示的数据层级均为“已开票”。
        </Alert>
      )}

      <Stack direction="row" sx={{ mt: "42px", mb: 4 }}>
        <Typography variant="h1" sx={{ mr: "12px" }}>
          {t("WELCOME_BACK")} {user.name}!{" "}
          {countryDetails[user.countries[0]].name === "China" ? t("CHINESE_VIEW_DATA") : t("VIEW_DATA")}
          {countryDetails[user.countries[0]].name !== "China" ? countryDetails[user.countries[0]].name : ""}
        </Typography>

        {/* <Dropdown
          optionData={user.countries.map((item) => {
            return {
              value: item,
              label: countryDetails[item].name,
            };
          })}
          preSelectedValue={{
            value: user.countries[0],
            label: countryDetails[user.countries[0]].name,
          }}
        /> */}
      </Stack>
      <Stack direction="row" sx={{ mt: "42px", mb: "4px" }}>
        <InsightsV2 />
      </Stack>
    </Container>
  );
};

export default InsightsPageV2;
